import { SyntheticEvent, useCallback } from 'react';
import { useState } from 'react';
// material-ui
import CheckIcon from '@mui/icons-material/Check';
import { Avatar, Box, Button, Fade, Grow, IconButton, Slide, SlideProps, Typography, Card } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';
import React from 'react';
// assets
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'store';
import { closeSnackbar } from 'store/slices/snackbar';
import { KeyedObject } from 'types';
import INotification from 'types/i-notification';
import { END_POINT, ROUTE_NAME } from 'configs';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setTaskItem } from 'store/slices/task';
import axios from 'utils/axios';
import { openSnackbar } from 'store/slices/snackbar';
import TaskFrm from 'views/task/TaskFrm';
import Chip from 'ui-component/extended/Chip';
import { ITask } from 'types/i-task';
import ChangeDeadlineConfirmedDialog from 'views/task/ChangeDeadlineConfirmedDialog';
import HowApproachDialog from 'views/task/HowApproachDialog';
import AskTypeMeeting from 'views/lead/popup/AskTypeMeeting';
import DoneConfirmedDialog from 'views/task/DoneConfirmedDialog';
// animation function
function TransitionSlideLeft(props: SlideProps) {
    return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: SlideProps) {
    return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: SlideProps) {
    return <Slide {...props} direction="down" />;
}

function GrowTransition(props: SlideProps) {
    return <Grow {...props} />;
}

// animation options
const animation: KeyedObject = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade,
};
interface IMarkReadSaved {
    id?: string | null;
    mark_all: boolean;
}
interface IRealTimeSaved {
    id: string;
    is_read_realtime: boolean;
}
interface IViewNotificationSaved {
    is_viewed_notification: boolean;
}
interface ITaskChangedStatus {
    id: number[];
    status: string;
}
// ==============================|| SNACKBAR ||============================== //
interface IUpdateSyncSaved {
    product_service_id: number;
    status: number;
}
const Snackbar = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const snackbar = useSelector((state) => state.snackbar);
    const { actionButton, anchorOrigin, alert, close, message, open, transition, variant, title, item } = snackbar;
    const [isOpenTaskFrm, setOpenTaskFrm] = useState<boolean>(false);
    const [isOpenChangeDeadlineConfirmedDialog, setOpenChangeDeadlineConfirmedDialog] = useState<boolean>(false);
    const [taskId, setTaskId] = useState<number>(0);
    const [isOpenHowApproachDialog, setOpenHowApproachDialog] = useState<boolean>(false);
    const [taskItem, setTaskItemHowApproach] = useState<ITask | null>(null);
    const [notiId, setNotiId] = useState<string>('');
    const [isOpenAskTypeMeeting, setOpenAskTypeMeeting] = useState<boolean>(false);
    const [pointTypeSubFirst, setPointTypeSubFirst] = useState<string>('');
    const [isOpenDoneConfirmedDialog, setOpenDoneConfirmedDialog] = React.useState<boolean>(false);
    const handleOpenTaskFrm = () => {
        setOpenTaskFrm(true);
    };

    const handleCloseTaskFrm = useCallback(() => {
        dispatch(setTaskItem({ taskItem: null }));
        setOpenTaskFrm(false);
    }, []);

    const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(closeSnackbar());
    };

    const handleClick = (notiItem: INotification) => async () => {
        try {
            let object_id: number = notiItem && notiItem.object_id ? notiItem.object_id : 0;
            let id: string | null = notiItem && notiItem.id ? notiItem.id.toString().trim() : null;
            let type: string = notiItem && notiItem.type ? notiItem.type : '';
            let data: IMarkReadSaved = {
                id,
                mark_all: false,
            };
            const rsMark: any = await axios.put(`${END_POINT.API_NOTIFICATION_MARK_READ}`, data);
            let notifiLst: INotification[] = rsMark.data.data.items && rsMark.data.data.items.length > 0 ? rsMark.data.data.items : [];
            switch (type) {
                case 'task':
                    const rsTaskDetail: any = await axios.get(`${END_POINT.API_TASK_SHOW_DETAIL}/${object_id}`, {
                        headers: { isShowLoading: true },
                    });
                    const { status } = rsTaskDetail.data;
                    if (status) {
                        dispatch(setTaskItem({ taskItem: rsTaskDetail.data.data.items ? rsTaskDetail.data.data.items : null }));
                        handleOpenTaskFrm();
                    }
                    break;
                case 'sync_product':
                    navigate(`${ROUTE_NAME.PRODUCT_SERVICE_EDIT}/${object_id}`);
                    break;
                case 'deal':
                    navigate(`${ROUTE_NAME.TARGET_ANALYSIS}/${object_id}`);
                    break;
            }
        } catch (err: any) {}
    };
    const handleCloseDoneConfirmedDialog = React.useCallback(() => {
        setOpenDoneConfirmedDialog(false);
    }, []);
    const handleUpdateSyncProduct = (notiItem: any, statusSync: number) => async () => {
        let msgSuccess = '';
        let msgFailure = '';
        switch (statusSync) {
            case 1:
                msgSuccess = t('create_new_product_successfully');
                msgFailure = t('create_new_product_failure');
                break;
            case 2:
                msgSuccess = t('overwrite_product_successfully');
                msgFailure = t('overwrite_product_failure');
                break;
        }
        let checked = true;
        let txtMsg = '';
        let typeMsg = 'error';
        try {
            if (notiItem.object_id === null || notiItem.object_id === undefined) {
                checked = false;
                txtMsg = t('checked_to_sync_product');
            }
            if (checked) {
                let dataSaved: IUpdateSyncSaved = {
                    product_service_id: notiItem.object_id,
                    status: statusSync,
                };
                let res: any = await axios.post(`/product-service/update-status-sync-data`, dataSaved, {
                    headers: { isShowLoading: true },
                });
                const { status } = res.data;
                if (status) {
                    let resDeleteNoti: any = await axios.delete(END_POINT.API_DELETE_NOTI, {
                        params: { id: notiItem.id },
                        headers: { isShowLoading: true },
                    });
                    typeMsg = 'success';
                    txtMsg = msgSuccess;
                    dispatch(closeSnackbar());
                } else {
                    txtMsg = msgFailure;
                }
            }
        } catch (err: any) {
            if (err?.data?.message) {
                txtMsg = err.data.message;
            } else if (err?.message) {
                txtMsg = err.message;
            } else {
                txtMsg = t('error_system');
            }
        }
        dispatch(
            openSnackbar({
                open: true,
                message: txtMsg,
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                variant: 'alert',
                alert: {
                    color: typeMsg,
                },
                transition: 'Fade',
                close: false,
            })
        );
    };

    const handleCancleSyncProduct = (notiItem: any) => async () => {
        let txtMsg = '';
        let typeMsg = 'error';
        try {
            let res: any = await axios.delete(END_POINT.API_DELETE_NOTI, { params: { id: notiItem.id }, headers: { isShowLoading: true } });
            const { status } = res.data;
            if (status) {
                dispatch(closeSnackbar());
            }
        } catch (err: any) {
            if (err?.data?.message) {
                txtMsg = err.data.message;
            } else if (err?.message) {
                txtMsg = err.message;
            } else {
                txtMsg = t('error_system');
            }
            dispatch(
                openSnackbar({
                    open: true,
                    message: txtMsg,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    variant: 'alert',
                    alert: {
                        color: typeMsg,
                    },
                    transition: 'Fade',
                    close: false,
                })
            );
        }
    };
    const getBoxPriority = (notiItem: INotification) => {
        let reactNode: React.ReactNode = <React.Fragment></React.Fragment>;
        if (notiItem.meta && notiItem.meta.priority && notiItem.type === 'change_deadline_task') {
            let labelChip: string = notiItem.meta.priority.toString().toUpperCase();
            switch (notiItem.meta.priority) {
                case 'high':
                    reactNode = (
                        <Box>
                            <Chip chipcolor="error" label={labelChip} />
                        </Box>
                    );
                    break;
                case 'medium':
                    reactNode = (
                        <Box>
                            <Chip chipcolor="warning" label={labelChip} />
                        </Box>
                    );
                    break;
                case 'low':
                    reactNode = (
                        <Box>
                            <Chip chipcolor="success" label={labelChip} />
                        </Box>
                    );
                    break;
            }
        }
        return reactNode;
    };
    const handleConfirmTaskDone = (notiItem: any) => async () => {
        let taskId: number = notiItem && notiItem.object_id ? parseInt(notiItem.object_id.toString()) : 0;
        const rsTaskDetail = await axios.get(`${END_POINT.API_TASK_SHOW_DETAIL}/${taskId}`, { headers: { isShowLoading: true } });
        const { status, message, data } = rsTaskDetail.data;
        if (status) {
            let itemTask: ITask | null = data.items ? data.items : null;
            if (itemTask) {
                let ids: number[] = [];
                ids.push(taskId);
                let dataSaved: ITaskChangedStatus = {
                    id: ids,
                    status: 'is_completed',
                };
                setTaskItemHowApproach(itemTask);
                switch (itemTask.type) {
                    case 'task':
                        if (itemTask.task_perform === 'call') {
                            const rsUpdateTask: any = await axios.put(`${END_POINT.API_TASK_UPDATE_TASK}`, dataSaved, {
                                headers: { isShowLoading: true },
                            });
                            setOpenHowApproachDialog(true);
                        }
                        break;
                    case 'meeting':
                        setOpenAskTypeMeeting(true);
                        break;
                }
                dispatch(
                    openSnackbar({
                        open: true,
                        message: t('done_task_successfully'),
                        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                        variant: 'alert',
                        alert: {
                            color: 'success',
                        },
                        transition: 'Fade',
                        close: false,
                    })
                );
            }
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    variant: 'alert',
                    alert: {
                        color: 'error',
                    },
                    transition: 'Fade',
                    close: false,
                })
            );
        }
    };
    const handleConfirmTaskNotYet = (notiItem: any) => () => {
        let objectId: number = notiItem && notiItem.object_id ? parseInt(notiItem.object_id.toString()) : 0;
        setTaskId(objectId);
        setNotiId(notiItem.id);
        setOpenChangeDeadlineConfirmedDialog(true);
    };
    const handleCloseChangeDeadlineConfirmedDialog = useCallback(async () => {
        setOpenChangeDeadlineConfirmedDialog(false);
    }, []);
    const handleCloseHowApproachDialog = useCallback(() => {
        setOpenHowApproachDialog(false);
    }, []);
    const handleAfterAskTypeMeeting = async (callbackData: any) => {
        const actionUrl = `${END_POINT.API_TASK_UPDATE_TASK}`;
        let params = {
            id: taskItem && taskItem.id ? (taskItem.id as any) : null,
            status: 'is_completed',
            meeting_type: callbackData,
        };
        const res = await axios.put(actionUrl, params, { headers: { isShowLoading: false } });
        if (res.status) {
            switch (parseInt(callbackData)) {
                case 0:
                    setPointTypeSubFirst('initial_meeting');
                    break;
                case 1:
                    setPointTypeSubFirst('closing_meeting');
                    break;
                case 2:
                    setPointTypeSubFirst('2_in_1');
                    break;
            }
            setOpenHowApproachDialog(true);
        }
    };
    const getNodeHtmlNoti = (item: any) => {
        let nodeReact: React.ReactElement = <React.Fragment></React.Fragment>;
        switch (item.type) {
            // case 'change_deadline_task':
            // case 'remind_task_meeting':
            //     nodeReact = (
            //         <Box
            //             sx={{ boxShadow: '0px 2px 14px rgb(0 0 0 / 10%)', p: 2, background: '#FFF', borderRadius: '3px' }}
            //             display="flex"
            //             justifyContent="space-between"
            //             alignItems="start"
            //             columnGap={2}
            //         >
            //             <Box
            //                 display="flex"
            //                 alignItems="center"
            //                 justifyContent="center"
            //                 sx={{
            //                     background: alert.color === 'success' ? theme.palette.success.main : theme.palette.error.main,
            //                     color: '#FFF',
            //                     borderRadius: '50%',
            //                     mt: 1,
            //                     boxShadow: '12px 11px 20px 5px #ccc',
            //                 }}
            //             ></Box>
            //             <Box flexGrow={1} alignItems="start">
            //                 <Typography
            //                     component="div"
            //                     variant="subtitle2"
            //                     sx={{ color: theme.palette.grey[900], fontWeight: 500 }}
            //                     dangerouslySetInnerHTML={{ __html: message }}
            //                 ></Typography>
            //                 <Card sx={{ mt: 2, boxShadow: '1' }}>
            //                     <Box
            //                         sx={{
            //                             py: 1,
            //                             px: 1,
            //                         }}
            //                     >
            //                         <Box display="flex" alignItems="center" columnGap={1}>
            //                             {getBoxPriority(item)}
            //                             <Box sx={{ color: theme.palette.grey[900], fontWeight: 800 }}>
            //                                 {item.meta && item.meta.title ? item.meta.title : ''}
            //                             </Box>
            //                         </Box>
            //                         <Box display="flex" alignItems="center" columnGap={1} sx={{ color: theme.palette.grey[500], mt: 1 }}>
            //                             <Avatar
            //                                 src={item.meta && item.meta.lead_avatar ? item.meta.lead_avatar : ''}
            //                                 sx={{
            //                                     background: 'transparent',
            //                                     width: 30,
            //                                     height: 30,
            //                                     cursor: 'pointer',
            //                                 }}
            //                             />
            //                             <Box>{item.meta && item.meta.lead_name ? item.meta.lead_name : ''}</Box>
            //                         </Box>
            //                     </Box>
            //                 </Card>
            //                 <Box display="flex" justifyContent="flex-end" columnGap={1} sx={{ mt: 1 }}>
            //                     <Button
            //                         variant="text"
            //                         onClick={handleConfirmTaskDone(item)}
            //                         sx={{
            //                             color: theme.palette.success.main,
            //                             fontWeight: 500,
            //                             p: 0,
            //                             display: 'inline-block',
            //                             textAlign: 'left',
            //                             minWidth: 'auto',
            //                             width: 'auto',
            //                             fontSize: ' 0.875rem',
            //                         }}
            //                     >
            //                         {t('done')}
            //                     </Button>
            //                     <Button
            //                         variant="text"
            //                         onClick={handleConfirmTaskNotYet(item)}
            //                         sx={{
            //                             color: theme.palette.error.main,
            //                             fontWeight: 500,
            //                             p: 0,
            //                             display: 'inline-block',
            //                             textAlign: 'left',
            //                             minWidth: 'auto',
            //                             width: 'auto',
            //                             fontSize: ' 0.875rem',
            //                         }}
            //                     >
            //                         {t('not_yet')}
            //                     </Button>
            //                 </Box>
            //             </Box>
            //         </Box>
            //     );
            //     break;
            default:
                nodeReact = (
                    <Box
                        sx={{ boxShadow: '0px 2px 14px rgb(0 0 0 / 10%)', p: 2, background: '#FFF', borderRadius: '3px' }}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="start"
                        columnGap={2}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                // width: '20px',
                                // height: '20px',
                                background: alert.color === 'success' ? theme.palette.success.main : theme.palette.error.main,
                                color: '#FFF',
                                borderRadius: '50%',
                                mt: 1,
                                boxShadow: '12px 11px 20px 5px #ccc',
                            }}
                        ></Box>
                        <Box flexGrow={1} alignItems="start">
                            {item.title && (
                                <Typography component="div" variant="subtitle1" sx={{ color: theme.palette.grey[900], fontWeight: 600 }}>
                                    {item.avatar && <img src={item.avatar} width={'12px'} height={'12px'} style={{ marginRight: '5px' }} />}
                                    {title}
                                </Typography>
                            )}
                            <Typography
                                component="div"
                                variant="subtitle2"
                                sx={{ color: theme.palette.grey[900], fontWeight: 500 }}
                                dangerouslySetInnerHTML={{ __html: message }}
                            ></Typography>
                            <div className="flex-end">
                                {item.type !== 'system' && (
                                    <Button
                                        variant="text"
                                        onClick={() => handleClick(item)}
                                        sx={{
                                            color: theme.palette.success.main,
                                            p: 0,
                                            display: 'inline-block',
                                            textAlign: 'left',
                                            minWidth: 'auto',
                                            width: 'auto',
                                            fontSize: ' 0.875rem',
                                            mr: 1,
                                        }}
                                    >
                                        {t('View detail')}
                                    </Button>
                                )}
                                <Button
                                    variant="text"
                                    onClick={handleClose}
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontWeight: 500,
                                        p: 0,
                                        display: 'inline-block',
                                        textAlign: 'left',
                                        minWidth: 'auto',
                                        width: 'auto',
                                        fontSize: ' 0.875rem',
                                    }}
                                >
                                    {t('Dismiss')}
                                </Button>
                            </div>
                        </Box>
                    </Box>
                );
                break;
        }
        return nodeReact;
    };
    return (
        <>
            {/* default snackbar */}
            {variant === 'default' && (
                <MuiSnackbar
                    className="alert-custom"
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={message}
                    TransitionComponent={animation[transition]}
                    action={
                        <>
                            <Button color="secondary" size="small" onClick={handleClose}>
                                UNDOS
                            </Button>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} sx={{ mt: 0.25 }}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </>
                    }
                />
            )}

            {/* alert snackbar */}
            {variant === 'alert' && (
                <MuiSnackbar
                    className="alert-custom"
                    TransitionComponent={animation[transition]}
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Box
                        sx={{ boxShadow: '0px 2px 14px rgb(0 0 0 / 10%)', p: 2, background: '#FFF', borderRadius: '3px' }}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="start"
                        columnGap={2}
                        className="w-100"
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                background: alert.color === 'success' ? theme.palette.success.main : theme.palette.error.main,
                                color: '#FFF',
                                borderRadius: '50%',
                                mt: 1,
                                boxShadow: '12px 11px 20px 5px #ccc',
                            }}
                        >
                            {alert.color === 'success' ? <CheckIcon sx={{ fontSize: '18px' }} /> : <ClearIcon sx={{ fontSize: '18px' }} />}
                        </Box>
                        <Box flexGrow={1} alignItems="start">
                            <Typography component="div" variant="subtitle1" sx={{ color: theme.palette.grey[900], fontWeight: 600 }}>
                                {t('Roamie system')}
                            </Typography>
                            <Typography
                                component="div"
                                variant="subtitle2"
                                sx={{ color: theme.palette.grey[900], fontWeight: 500 }}
                                dangerouslySetInnerHTML={{ __html: message }}
                            ></Typography>
                            <div className="flex-end">
                                <Button
                                    variant="text"
                                    onClick={handleClose}
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontWeight: 500,
                                        p: 0,
                                        display: 'inline-block',
                                        textAlign: 'left',
                                        minWidth: 'auto',
                                        width: 'auto',
                                    }}
                                >
                                    {t('Dismiss')}
                                </Button>
                            </div>
                        </Box>
                    </Box>
                </MuiSnackbar>
            )}
            {/* noti */}
            {variant === 'noti' && (
                <MuiSnackbar
                    className="alert-custom"
                    TransitionComponent={animation[transition]}
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    {getNodeHtmlNoti(item)}
                </MuiSnackbar>
            )}
            {/* {variant === 'sync_product' && (
                <MuiSnackbar
                    className="alert-custom"
                    TransitionComponent={animation[transition]}
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Box
                        sx={{ boxShadow: '0px 2px 14px rgb(0 0 0 / 10%)', p: 2, background: '#FFF', borderRadius: '3px' }}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="start"
                        columnGap={2}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                // width: '20px',
                                // height: '20px',
                                background: alert.color === 'success' ? theme.palette.success.main : theme.palette.error.main,
                                color: '#FFF',
                                borderRadius: '50%',
                                mt: 1,
                                boxShadow: '12px 11px 20px 5px #ccc',
                            }}
                        ></Box>
                        <Box flexGrow={1} alignItems="start">
                            {item.title && (
                                <Typography component="div" variant="subtitle1" sx={{ color: theme.palette.grey[900], fontWeight: 600 }}>
                                    {item.avatar && <img src={item.avatar} width={'12px'} height={'12px'} style={{ marginRight: '5px' }} />}
                                    {title}
                                </Typography>
                            )}
                            <Typography
                                component="div"
                                variant="subtitle2"
                                sx={{ color: theme.palette.grey[900], fontWeight: 500 }}
                                dangerouslySetInnerHTML={{ __html: message }}
                            ></Typography>
                            <Box display="flex" justifyContent="flex-end" columnGap={1}>
                                <Button
                                    variant="text"
                                    onClick={handleUpdateSyncProduct(item, 1)}
                                    sx={{
                                        color: theme.palette.success.dark,
                                        fontWeight: 500,
                                        p: 0,
                                        display: 'inline-block',
                                        textAlign: 'left',
                                        minWidth: 'auto',
                                        width: 'auto',
                                        fontSize: ' 0.875rem',
                                    }}
                                >
                                    {t('new_one')}
                                </Button>
                                <Button
                                    variant="text"
                                    onClick={handleUpdateSyncProduct(item, 2)}
                                    sx={{
                                        color: theme.palette.warning.dark,
                                        fontWeight: 500,
                                        p: 0,
                                        display: 'inline-block',
                                        textAlign: 'left',
                                        minWidth: 'auto',
                                        width: 'auto',
                                        fontSize: ' 0.875rem',
                                    }}
                                >
                                    {t('overwrite')}
                                </Button>
                                <Button
                                    variant="text"
                                    onClick={handleCancleSyncProduct(item)}
                                    sx={{
                                        color: theme.palette.grey[400],
                                        fontWeight: 500,
                                        p: 0,
                                        display: 'inline-block',
                                        textAlign: 'left',
                                        minWidth: 'auto',
                                        width: 'auto',
                                        fontSize: ' 0.875rem',
                                    }}
                                >
                                    {t('Dismiss')}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </MuiSnackbar>
            )} */}
            <TaskFrm isOpenTaskFrm={isOpenTaskFrm} closeTaskFrm={handleCloseTaskFrm} />
            <DoneConfirmedDialog
                onCloseDoneConfirmedDialog={handleCloseDoneConfirmedDialog}
                isOpenDoneConfirmedDialog={isOpenDoneConfirmedDialog}
                taskId={taskId}
                onSetTaskData={item}
                dataType={'today'}
            />
            <ChangeDeadlineConfirmedDialog
                onCloseChangeDeadlineConfirmedDialog={handleCloseChangeDeadlineConfirmedDialog}
                isOpenChangeDeadlineConfirmedDialog={isOpenChangeDeadlineConfirmedDialog}
                taskId={taskId}
                notiId={notiId}
            />
            <HowApproachDialog
                onCloseHowApproachDialog={handleCloseHowApproachDialog}
                isOpenHowApproachDialog={isOpenHowApproachDialog}
                taskItem={taskItem}
                point_type_sub_first={pointTypeSubFirst}
            />
            <AskTypeMeeting
                open={isOpenAskTypeMeeting}
                handleCloseDialog={() => setOpenAskTypeMeeting(false)}
                callback={(callBackData) => {
                    handleAfterAskTypeMeeting(callBackData);
                }}
            />
        </>
    );
};

export default Snackbar;
