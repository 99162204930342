import { END_POINT } from 'configs';
import axiosServices from 'utils/axios';

export const useLead = () => {
    const fetchLeadDetail = async (leadId: number) => {
        const res = await axiosServices.get(`${END_POINT.API_LEAD_SHOW}/${leadId}`);
        const { status, data } = res.data;
        if (status) {
            return data.items;
        }
    };

    const onCreateLead = async (values: any) => {
        const res = await axiosServices.post(`${END_POINT.API_LEAD_CREATE}`, values, { headers: { isShowLoading: true } });
        const { status, data } = res.data;
        if (status) {
            return data.items;
        }
    };

    const onUpdateStepData = async (leadId: number, values: any, return_all = false) => {
        const actionUrl = `${END_POINT.API_LEAD_UPDATE}/${leadId}`;
        const res = await axiosServices.put(actionUrl, values, { headers: { isShowLoading: true } });
        if(return_all){
            return res;
        }
        const { status, data } = res.data;
        if (status) {
            return data.items;
        }
    };

    return { fetchLeadDetail, onCreateLead, onUpdateStepData };
};
