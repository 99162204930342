import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// material-ui
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import useConfig from 'hooks/useConfig';
import { NavGroupProps } from '../NavGroup';
import NavItem from '../NavItem';
import { useDispatch, useSelector } from 'store';
import { activeItem, openDrawer,activeParentItem } from 'store/slices/menu';
// assets
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NAME } from 'configs';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

interface NavCollapseProps {
    menu: NavGroupProps['item'];
    level: number;
}

const NavCollapse = ({ menu, level }: NavCollapseProps) => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selectedParentItem } = useSelector((state) => state.menu);
    const [open, setOpen] = useState(menu.id === selectedParentItem[0]);
    const [selected, setSelected] = useState<string | null | undefined>(menu.id);

    const handleClick = () => {
        setOpen(!open);
        setSelected(menu.id);
        dispatch(activeParentItem([menu.id]));
        if(menu.children){
            navigate(menu.children[0].url ?? "");
            dispatch(activeItem([menu.children[0].id]));
        }
    };

    const { pathname } = useLocation();
    // const { pathname } = useRouter();
    const checkOpenForParent = (child: any, id?: string) => {
        child.forEach((item: any) => {
            if (item.url === pathname) {
                setOpen(true);
                setSelected(id);
                dispatch(activeParentItem([id]));
                dispatch(activeItem([""]));
            }
        });
    };

    // menu collapse for sub-levels
    useEffect(() => {
        const childrens = menu.children ? menu.children : [];
        childrens.forEach((item: any) => {
            if (item.children) {
                checkOpenForParent(item.children, menu.id);
            // }else if (pathname && (pathname.includes('personal') || pathname.includes('company'))) {
            //     if (item.url && (item.url.includes('personal') || item.url.includes('company'))) {
            //         setSelected(menu.id);
            //         setOpen(true);
            //     }
            }else if (pathname && (pathname.includes('leads') || pathname.includes('target'))) {
                if (item.url && (item.url.includes('leads') || item.url.includes('target'))) {
                    setSelected(menu.id);
                    setOpen(true);
                    dispatch(activeParentItem([menu.id]));
                }
            }else if (item.url === pathname) {
                setSelected(menu.id);
                setOpen(true);
                dispatch(activeParentItem([menu.id]));
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, menu.children]);

    // menu collapse & item
    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = menu.icon!;
    const menuIcon = menu.icon ? (
        <Icon strokeWidth={1.5} size="20px" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selected === menu.id ? 8 : 6,
                height: selected === menu.id ? 8 : 6,
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    return (
        <>
            <ListItemButton
                sx={{
                    borderRadius: `${borderRadius}px`,
                    mb: 0.5,
                    alignItems: 'flex-start',
                    backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    py: level > 1 ? 1 : 1.25,
                    pl: `${level * 24}px`,
                }}
                selected={selected === selectedParentItem[0]}
                onClick={handleClick}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant={selected === menu.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}>
                            {menu.title}
                        </Typography>
                    }
                    secondary={
                        menu.caption && (
                            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                {menu.caption}
                            </Typography>
                        )
                    }
                />
                {open ? (
                    <IconChevronUp stroke={1.5} size="16px" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                ) : (
                    <IconChevronDown stroke={1.5} size="16px" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {open && (
                    <List
                        component="div"
                        disablePadding
                        sx={{
                            position: 'relative',
                            '&:after': {
                                content: "''",
                                position: 'absolute',
                                left: '32px',
                                top: 0,
                                height: '100%',
                                width: '1px',
                                opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
                                background: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary.light,
                            },
                        }}
                    >
                        {menus}
                    </List>
                )}
            </Collapse>
        </>
    );
};

export default NavCollapse;
