import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultRootStateProps } from 'types';
import { BreadcrumbStateProps } from 'types/breadcrumb';

const initialState: DefaultRootStateProps['breadcrumb'] = {
    title_breadcrumb: '',
};
const slice = createSlice({
    name: 'breadcrumb-slice',
    initialState,
    reducers: {
        setTitleBreadcrumb: (state, action: PayloadAction<BreadcrumbStateProps>) => {
            state.title_breadcrumb = action.payload.title_breadcrumb;
        },
    },
});
export default slice.reducer;
export const { setTitleBreadcrumb } = slice.actions;
