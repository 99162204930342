import { createSlice } from '@reduxjs/toolkit';
import { DefaultRootStateProps } from 'types';

const initialState: DefaultRootStateProps['dialog'] = {
    isShow: false,
    isAgree: false,
};
const slice = createSlice({
    name: 'dialog-slice',
    initialState,
    reducers: {
        showDialog: (state) => {
            state.isShow = true;
            state.isAgree = false;
        },
        hideDialog: (state) => {
            state.isShow = false;
        },
        agree: (state) => {
            state.isAgree = true;
        },
    },
});
export default slice.reducer;
export const { showDialog, hideDialog, agree } = slice.actions;
