// third-party
import { FormattedMessage } from 'react-intl';

import { IconChartDonut2 } from '@tabler/icons';
import { ROUTE_NAME } from 'configs';

const pointSystemSeller = {
    id: 'point_system_seller',
    title: <FormattedMessage id="point_system" />,
    type: 'group',
    url: '/point-system-seller',
    icon: IconChartDonut2,
    breadcrumbs: true,
};

export default pointSystemSeller;
