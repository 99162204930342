import { ROUTE_NAME } from "configs";
import { Navigate, useRoutes } from "react-router-dom";
import AuthenticationRoutes from "./AuthenticationRoutes";
import LoginRoutes from "./LoginRoutes";
// import MainRoutes from './MainRoutes';
import ManagerRoutes from "./ManagerRoutes";
import SellerRoutes from "./SellerRoutes";
import useAuth from "hooks/useAuth";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
	const { user } = useAuth();
	return useRoutes([
		{ path: "/", element: <Navigate to="/login" /> },
		AuthenticationRoutes,
		LoginRoutes,
		user?.type == "manager" ? ManagerRoutes : SellerRoutes
	]);
}
