// third-party
import { FormattedMessage } from 'react-intl';

import GridViewIcon from '@mui/icons-material/GridView';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    url: '/dashboard',
    icon: GridViewIcon,
    breadcrumbs: true,
};

export default dashboard;
