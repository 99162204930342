import React from "react";

// material-ui
import { Box, Button, Dialog } from "@mui/material";
// assets
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/styles";
import { END_POINT } from "configs";
import { useTranslation } from "react-i18next";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import { setTaskItem } from "store/slices/task";
import { ITask } from "types/i-task";
import axios from "utils/axios";
import TaskFrm from "./TaskFrm";
interface ChangeDeadlineConfirmedDialogProps {
	onCloseChangeDeadlineConfirmedDialog: () => void;
	isOpenChangeDeadlineConfirmedDialog: boolean;
	taskId: number;
	notiId: string;
	loadNotificationLst?: () => void;
}
interface ITaskChangedStatus {
	id: number[];
	status: string;
}
function ChangeDeadlineConfirmedDialog({
	onCloseChangeDeadlineConfirmedDialog,
	isOpenChangeDeadlineConfirmedDialog,
	taskId,
	notiId,
	loadNotificationLst
}: ChangeDeadlineConfirmedDialogProps) {
	const { t } = useTranslation();
	const theme = useTheme();
	const dispatch = useDispatch();
	const [isOpenTaskFrm, setOpenTaskFrm] = React.useState<boolean>(false);
	const [notificationId, setNotificationId] = React.useState<string>("");
	const handleConfirmNo = async () => {
		let txtMsg = "";
		let typeMsg = "error";
		let checked = true;
		let res: any = null;
		try {
			if (checked) {
				res = await axios.delete(END_POINT.API_DELETE_NOTI, { params: { id: notiId } });
				const { status } = res.data;
				if (status) {
					typeMsg = "success";
					txtMsg = t("task_is_change_to_overdue");
					onCloseChangeDeadlineConfirmedDialog();
					if (loadNotificationLst) {
						loadNotificationLst();
					}
				}
			}
		} catch (err: any) {
			if (err?.data?.message) {
				txtMsg = err.data.message;
			} else if (err?.message) {
				txtMsg = err.message;
			} else {
				txtMsg = t("error_system");
			}
		}
		dispatch(
			openSnackbar({
				open: true,
				message: txtMsg,
				anchorOrigin: { vertical: "bottom", horizontal: "left" },
				variant: "alert",
				alert: {
					color: typeMsg
				},
				transition: "Fade",
				close: false
			})
		);
	};
	const handleConfirmYes = async () => {
		if (taskId > 0) {
			const rsTaskDetail: any = await axios.get(`${END_POINT.API_TASK_SHOW_DETAIL}/${taskId}`, {
				headers: { isShowLoading: true }
			});
			const { status, message, data } = rsTaskDetail.data;
			if (status) {
				let taskItem: ITask | null = data.items ? data.items : null;
				setNotificationId(notiId);
				dispatch(setTaskItem({ taskItem }));
				setOpenTaskFrm(true);
				onCloseChangeDeadlineConfirmedDialog();
				if (loadNotificationLst) {
					loadNotificationLst();
				}
			} else {
				dispatch(
					openSnackbar({
						open: true,
						message,
						anchorOrigin: { vertical: "bottom", horizontal: "left" },
						variant: "alert",
						alert: {
							color: "error"
						},
						transition: "Fade",
						close: false
					})
				);
			}
		}
	};
	const handleCloseTaskFrm = React.useCallback(() => {
		setOpenTaskFrm(false);
	}, []);
	return (
		<React.Fragment>
			<Dialog
				open={isOpenChangeDeadlineConfirmedDialog}
				onClose={onCloseChangeDeadlineConfirmedDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				sx={{
					"&>div:nth-of-type(3)": {
						"&>div": {
							maxWidth: 500,
							width: 500
						}
					}
				}}
			>
				{isOpenChangeDeadlineConfirmedDialog && (
					<Box sx={{ p: 3 }}>
						<Box display="flex" justifyContent="flex-end">
							<Button
								onClick={onCloseChangeDeadlineConfirmedDialog}
								variant="contained"
								sx={{
									borderRadius: "50%",
									padding: "6px 6px 6px 6px",
									minWidth: "auto",
									background: "#E94D64",
									"&:hover": {
										background: "#ef1f3e"
									}
								}}
							>
								<CloseIcon sx={{ fontSize: "15px" }} />
							</Button>
						</Box>
						<Box
							sx={{
								mt: 2,
								fontSize: "20px",
								fontWeight: 800,
								color: theme.palette.primary.main,
								textAlign: "center"
							}}
						>
							{t("Task overdue")}!
						</Box>
						<Box sx={{ textAlign: "center", mt: 2 }}>
							{t("This task will be overdue now. Do you want to change the deadline")}?
						</Box>
						<Box sx={{ mt: 2 }} display="flex" justifyContent="center" columnGap={1}>
							<Button
								variant="contained"
								onClick={handleConfirmNo}
								sx={{
									backgroundColor: theme.palette.success.light,
									color: theme.palette.primary.main,
									"&:hover": {
										color: theme.palette.success.light,
										backgroundColor: theme.palette.secondary.main
									}
								}}
							>
								{t("no_confirm")}
							</Button>
							<Button
								variant="contained"
								onClick={handleConfirmYes}
								sx={{
									color: theme.palette.success.light,
									backgroundColor: theme.palette.primary.main,
									"&:hover": {
										color: theme.palette.success.light,
										backgroundColor: theme.palette.secondary.main
									}
								}}
							>
								{t("yes_confirm")}
							</Button>
						</Box>
					</Box>
				)}
			</Dialog>
			<TaskFrm
				isOpenTaskFrm={isOpenTaskFrm}
				closeTaskFrm={handleCloseTaskFrm}
				notificationId={notificationId ? notificationId : undefined}
				loadNotificationLst={loadNotificationLst}
			/>
		</React.Fragment>
	);
}
export default React.memo(ChangeDeadlineConfirmedDialog);
