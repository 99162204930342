import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import TrackChangesOutlinedIcon from '@mui/icons-material/TrackChangesOutlined';
import { FormattedMessage } from 'react-intl';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const sales = {
    id: 'sales',
    title: <FormattedMessage id="sales" />,
    type: 'collapse',
    icon: CrisisAlertIcon,
    children: [
        {
            id: 'target',
            title: <FormattedMessage id="target" />,
            type: 'item',
            url: '/target/list',
            icon: TrackChangesOutlinedIcon,
        },
        {
            id: 'leads',
            title: <FormattedMessage id="leads" />,
            type: 'item',
            url: '/leads/list',
            icon: AccountBoxOutlinedIcon,
        },
        /* {
            id: 'personals',
            title: <FormattedMessage id="person" />,
            type: 'item',
            url: '/leads/personal',
            icon: PersonOutlinedIcon,
        },
        {
            id: 'companies',
            title: <FormattedMessage id="company" />,
            type: 'item',
            url: '/leads/company',
            icon: ApartmentOutlinedIcon,
        }, */
        {
            id: 'deals',
            title: <FormattedMessage id="deals" />,
            type: 'item',
            url: '/deal/list',
            icon: AttachMoneyOutlinedIcon,
        },
    ],
};

export default sales;
