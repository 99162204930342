// third-party
import { FormattedMessage } from 'react-intl';

import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { ROUTE_NAME } from 'configs';
const seller = {
    id: 'seller',
    title: <FormattedMessage id="sellers" />,
    type: 'group',
    url: ROUTE_NAME.SELLER_LIST,
    icon: GroupOutlinedIcon,
    breadcrumbs: true,
};

export default seller;
