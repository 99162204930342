import { useState } from 'react';
// material-ui
import { Avatar, Box, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
// project imports
import { IconMenu2 } from '@tabler/icons';
import { END_POINT, ROUTE_NAME } from 'configs';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import { openSnackbar } from 'store/slices/snackbar';
import axios from 'utils/axios';
import LogoSection from '../LogoSection';
import CurrencySection from './CurrencySection';
import LocalizationSection from './LocalizationSection';
import MobileSection from './MobileSection';
import NotificationSection from './NotificationSection';
import ProfileSection from './ProfileSection';
import SearchSection from './SearchSection';
import { _fetchMenu } from 'store/slices/fetch';
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { user } = useAuth();
    const [role, setRole] = useState<string | undefined>(user?.type);
    const { isFetch } = useSelector((state) => state.fetch);

    async function swiffRoleAPI(_role: string) {
        try {
            const res = await axios.post(END_POINT.API_SWITCH_ROLE, { role: _role });
            const { status,data } = res.data;
            if (status) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: `${t('You are active under the account')} ${data.items.current_group_name}`,
                        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                        variant: 'alert',
                        alert: {
                            color: 'success',
                        },
                        close: false,
                    })
                );
                navigate(`${ROUTE_NAME.DASHBOARD}`);
                dispatch(_fetchMenu(!isFetch));
                // window.location.reload();
            }
        } catch (err: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: err?.data?.message ? err.data.message : t('error_system'),
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    variant: 'alert',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            );
        }
    }

    const swiffRole = (e: SelectChangeEvent) => {
        let _role = e.target.value as string;
        setRole(_role as string);
        swiffRoleAPI(_role);
    };
    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto',
                    },
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        border: '1px solid',
                        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
                        color: theme.palette.success.dark,
                        '&:hover': {
                            borderColor: theme.palette.success.main,
                            background: theme.palette.success.main,
                            color: theme.palette.success.light,
                        },
                    }}
                    onClick={() => dispatch(openDrawer(!drawerOpen))}
                    color="inherit"
                >
                    <IconMenu2 stroke={1.5} size="20px" />
                </Avatar>
            </Box>

            {/* header search */}
            <SearchSection />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />
            {/* {user?.roles?.includes('manager') && (
                <Box sx={{ display: { flexGrow: 1, maxWidth: '200px' } }}>
                    <Select labelId="swiff-account" id="swiff-account" value={role} onChange={swiffRole} fullWidth size="small">
                        <MenuItem value="manager">{t('Manager')}</MenuItem>
                        <MenuItem value="seller">{t('Seller')}</MenuItem>
                    </Select>
                </Box>
            )} */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <CurrencySection />
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box>
            <NotificationSection />
            <ProfileSection />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};

export default Header;
