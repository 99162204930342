// third-party
import { FormattedMessage } from 'react-intl';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { ROUTE_NAME } from 'configs';

const setting = {
    id: 'setting',
    title: <FormattedMessage id="setting" />,
    type: 'group',
    url: ROUTE_NAME.SETTING,
    icon: SettingsOutlinedIcon,
    breadcrumbs: true,
};

export default setting;
