import { NavItemType } from 'types';
import calendar from './calendar';
import contact from './contact';
import dashboard from './dashboard';
import report from './report';
import sales from './sales';
import salesManager from './salesManager';
import service from './service';
import setting from './setting';
import task from './task';
import chat from './chat';
import seller from './seller';
import productService from './productService';
import commissionManager from './commissionManager';
import commissionSeller from './commissionSeller';
import pointSystemManager from './pointSystemManager';
import pointSystemSeller from './pointSystemSeller';
import groups from './groups';

// ==============================|| MENU ITEMS ||============================== //

const menuItemsSeller: { items: NavItemType[] } = {
    // items: [dashboard, sales, seller, productService, contact, task, calendar, service, report, chat, commission, setting],
    items: [dashboard, sales, productService, contact, task, chat, pointSystemSeller, commissionSeller, setting], //, report, calendar, service
};

const menuItemsManager: { items: NavItemType[] } = {
    items: [dashboard, salesManager, contact, seller, productService, chat, pointSystemManager, commissionManager, setting], //, service, report
};

export { menuItemsSeller, menuItemsManager };
