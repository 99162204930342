// material-ui
import {
    Avatar,
    Box,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    Button,
    Card,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import swal from 'sweetalert';
// assets
import { END_POINT, ROUTE_NAME } from 'configs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { setTaskItem } from 'store/slices/task';
import INotification from 'types/i-notification';
import axios from 'utils/axios';
import { IconAddressBook, IconCirclePlus } from '@tabler/icons';
import { IconClipboardCheck } from '@tabler/icons';
import { IconCircleOff } from '@tabler/icons';
import ChangeDeadlineConfirmedDialog from 'views/task/ChangeDeadlineConfirmedDialog';
import HowApproachDialog from 'views/task/HowApproachDialog';
import { ITask } from 'types/i-task';
import Chip from 'ui-component/extended/Chip';
// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        backgroundColor: '#FFF8E1',
    },
    '& .MuiListItem-root': {
        padding: 0,
    },
}));
// ==============================|| NOTIFICATION LIST ITEM ||============================== //
interface NotificationProps {
    notificationLst: INotification[];
    onOpenTaskFrm: () => void;
    onToggle: () => void;
    loadNotificationLst: () => void;
    onConfirmTaskDone: (notiItem: INotification) => void;
    onConfirmTaskNotYet: (notiItem: INotification) => void;
}
interface IMarkReadSaved {
    id?: string | null;
    mark_all: boolean;
}
interface IUpdateSyncSaved {
    product_service_id: number;
    status: number;
}

const NotificationList = ({
    notificationLst,
    onOpenTaskFrm,
    onToggle,
    loadNotificationLst,
    onConfirmTaskDone,
    onConfirmTaskNotYet,
}: NotificationProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isOpenChangeDeadlineConfirmedDialog, setOpenChangeDeadlineConfirmedDialog] = React.useState<boolean>(false);
    const [taskId, setTaskId] = React.useState<number>(0);
    const [isOpenHowApproachDialog, setOpenHowApproachDialog] = React.useState<boolean>(false);
    const [taskItem, setTaskItemHowApproach] = React.useState<ITask | null>(null);
    const [notiId, setNotiId] = React.useState<string>('');
    let mounted = true;
    const chipSX = {
        height: 24,
        padding: '0 6px',
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
        marginRight: '5px',
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
        height: 28,
    };

    const handleClick = (notiItem: INotification) => async () => {
        try {
            let object_id: number = notiItem && notiItem.object_id ? notiItem.object_id : 0;
            let id: string | null = notiItem && notiItem.id ? notiItem.id.toString().trim() : null;
            let type: string = notiItem && notiItem.type ? notiItem.type : '';
            let data: IMarkReadSaved = {
                id,
                mark_all: false,
            };
            const rsMark: any = await axios.put(`${END_POINT.API_NOTIFICATION_MARK_READ}`, data);
            let notifiLst: INotification[] = rsMark.data.data.items && rsMark.data.data.items.length > 0 ? rsMark.data.data.items : [];
            switch (type) {
                case 'task':
                    const rsTaskDetail: any = await axios.get(`${END_POINT.API_TASK_SHOW_DETAIL}/${object_id}`, {
                        headers: { isShowLoading: true },
                    });
                    if (mounted) {
                        const { status } = rsTaskDetail.data;
                        if (status) {
                            dispatch(setTaskItem({ taskItem: rsTaskDetail.data.data.items ? rsTaskDetail.data.data.items : null }));
                            onOpenTaskFrm();
                        }
                    }
                    onToggle();
                    break;
                case 'deal':
                    onToggle();
                    navigate(`${ROUTE_NAME.TARGET_ANALYSIS}/${object_id}`);
                    break;
            }
            loadNotificationLst();
        } catch (err: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: err?.data?.message ? err.data.message : t('error_system'),
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    variant: 'alert',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            );
        }
        return () => {
            mounted = false;
        };
    };
    const handleUpdateSyncProduct = (notiItem: INotification, statusSync: number) => async () => {
        let msgSuccess = '';
        let msgFailure = '';
        switch (statusSync) {
            case 1:
                msgSuccess = t('create_new_product_successfully');
                msgFailure = t('create_new_product_failure');
                break;
            case 2:
                msgSuccess = t('overwrite_product_successfully');
                msgFailure = t('overwrite_product_failure');
                break;
        }
        let checked = true;
        let txtMsg = '';
        let typeMsg = 'error';
        try {
            if (notiItem.object_id === null || notiItem.object_id === undefined) {
                checked = false;
                txtMsg = t('checked_to_sync_product');
            }
            if (checked) {
                let dataSaved: IUpdateSyncSaved = {
                    product_service_id: notiItem.object_id,
                    status: statusSync,
                };
                let res: any = await axios.post(`/product-service/update-status-sync-data`, dataSaved, {
                    headers: { isShowLoading: true },
                });
                const { status } = res.data;
                if (status) {
                    let resDeleteNoti: any = await axios.delete(END_POINT.API_DELETE_NOTI, {
                        params: { id: notiItem.id },
                        headers: { isShowLoading: true },
                    });
                    typeMsg = 'success';
                    txtMsg = msgSuccess;
                    loadNotificationLst();
                } else {
                    txtMsg = msgFailure;
                }
            }
        } catch (err: any) {
            if (err?.data?.message) {
                txtMsg = err.data.message;
            } else if (err?.message) {
                txtMsg = err.message;
            } else {
                txtMsg = t('error_system');
            }
        }
        dispatch(
            openSnackbar({
                open: true,
                message: txtMsg,
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                variant: 'alert',
                alert: {
                    color: typeMsg,
                },
                transition: 'Fade',
                close: false,
            })
        );
    };
    const handleCancleSyncProduct = (notiItem: INotification) => async () => {
        let txtMsg = '';
        let typeMsg = 'error';
        try {
            let res: any = await axios.delete(END_POINT.API_DELETE_NOTI, { params: { id: notiItem.id }, headers: { isShowLoading: true } });
            const { status } = res.data;
            if (status) {
                loadNotificationLst();
            }
        } catch (err: any) {
            if (err?.data?.message) {
                txtMsg = err.data.message;
            } else if (err?.message) {
                txtMsg = err.message;
            } else {
                txtMsg = t('error_system');
            }
            dispatch(
                openSnackbar({
                    open: true,
                    message: txtMsg,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    variant: 'alert',
                    alert: {
                        color: typeMsg,
                    },
                    transition: 'Fade',
                    close: false,
                })
            );
        }
    };
    const getBoxPriority = (notiItem: INotification) => {
        let reactElmt = <React.Fragment></React.Fragment>;
        let labelChip = '';
        if (notiItem.meta && notiItem.meta.priority && notiItem.type === 'change_deadline_task') {
            labelChip = notiItem.meta.priority.toString().toUpperCase();
            switch (notiItem.meta.priority) {
                case 'high':
                    reactElmt = (
                        <Box>
                            <Chip chipcolor="error" label={labelChip} />
                        </Box>
                    );
                    break;
                case 'medium':
                    reactElmt = (
                        <Box>
                            <Chip chipcolor="warning" label={labelChip} />
                        </Box>
                    );
                    break;
                case 'low':
                    reactElmt = (
                        <Box>
                            <Chip chipcolor="success" label={labelChip} />
                        </Box>
                    );
                    break;
            }
        }
        return reactElmt;
    };
    const handleConfirmYes = (notiItem: INotification) => () => {
        onConfirmTaskDone(notiItem);
    };
    const handleConfirmNo = (notiItem: INotification) => () => {
        onConfirmTaskNotYet(notiItem);
    };
    const getGridReactNode = (notiItem: INotification) => {
        let reactElement: React.ReactNode = <React.Fragment></React.Fragment>;
        if (notiItem.type) {
            switch (notiItem.type) {
                case 'change_deadline_task':
                case 'remind_task_meeting':
                    reactElement = (
                        <React.Fragment>
                            <Grid container direction="column" className="list-container">
                                <Grid item xs={12} sx={{ pb: 2 }}>
                                    <React.Fragment>
                                        <Box>{notiItem.message}</Box>
                                        <Card sx={{ mt: 2, boxShadow: '1' }}>
                                            <Box
                                                sx={{
                                                    py: 1,
                                                    px: 1,
                                                }}
                                            >
                                                <Box display="flex" alignItems="center" columnGap={1}>
                                                    {getBoxPriority(notiItem)}
                                                    <Box sx={{ color: theme.palette.grey[900], fontWeight: 800 }}>
                                                        {notiItem.meta && notiItem.meta.title ? notiItem.meta.title : ''}
                                                    </Box>
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    columnGap={1}
                                                    sx={{ color: theme.palette.grey[500], mt: 1 }}
                                                >
                                                    <Avatar
                                                        src={notiItem.meta && notiItem.meta.lead_avatar ? notiItem.meta.lead_avatar : ''}
                                                        sx={{
                                                            background: 'transparent',
                                                            width: 30,
                                                            height: 30,
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                    <Box>{notiItem.meta && notiItem.meta.lead_name ? notiItem.meta.lead_name : ''}</Box>
                                                </Box>
                                            </Box>
                                        </Card>
                                    </React.Fragment>
                                </Grid>
                            </Grid>
                            {notiItem.meta && notiItem.meta.status && notiItem.meta.status === 'is_overdue' && (
                                <Box display="flex" justifyContent="flex-end" columnGap={1}>
                                    <Button variant="contained" color="primary" onClick={handleConfirmYes(notiItem)}>
                                        {t('done')}
                                    </Button>
                                    <Button variant="contained" color="error" onClick={handleConfirmNo(notiItem)}>
                                        {t('not_yet')}
                                    </Button>
                                </Box>
                            )}
                        </React.Fragment>
                    );
                    break;
                /* case 'sync_product':
                    reactElement = (
                        <Box display="flex" justifyContent="flex-end" columnGap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<IconCirclePlus />}
                                onClick={handleUpdateSyncProduct(notiItem, 1)}
                            >
                                {t('new_one')}
                            </Button>
                            <Button
                                variant="outlined"
                                color="warning"
                                startIcon={<IconClipboardCheck />}
                                onClick={handleUpdateSyncProduct(notiItem, 2)}
                            >
                                {t('overwrite')}
                            </Button>
                            <Button
                                variant="outlined"
                                color="error"
                                startIcon={<IconCircleOff />}
                                onClick={handleCancleSyncProduct(notiItem)}
                            >
                                {t('cancel')}
                            </Button>
                        </Box>
                    );
                    break; */
                default:
                    reactElement = (
                        <Grid container direction="column" className="list-container">
                            <Grid item xs={12} sx={{ pb: 2 }}>
                                <Typography
                                    component="div"
                                    variant="subtitle2"
                                    sx={{ color: '#000', fontSize: '14px' }}
                                    dangerouslySetInnerHTML={{ __html: notiItem.message ? notiItem.message : '' }}
                                ></Typography>
                            </Grid>
                        </Grid>
                    );
                    break;
            }
        }
        return reactElement;
    };
    return (
        <React.Fragment>
            <List
                sx={{
                    width: '100%',
                    maxWidth: 500,
                    py: 0,
                    borderRadius: '10px',
                    [theme.breakpoints.down('md')]: {
                        maxWidth: 300,
                    },
                    '& .MuiListItemSecondaryAction-root': {
                        top: 22,
                    },
                    '& .MuiDivider-root': {
                        my: 0,
                    },
                    '& .list-container': {
                        pl: 7,
                    },
                }}
            >
                {notificationLst.length > 0 ? (
                    notificationLst.map((notiItem, notiIndex) => {
                        return (
                            <React.Fragment key={`noti-item-${notiIndex}`}>
                                <ListItemWrapper
                                    onClick={handleClick(notiItem)}
                                    sx={{
                                        backgroundColor: notiItem && notiItem.is_read ? '#FFF' : '#FFF8E1',
                                    }}
                                >
                                    <ListItem alignItems="center">
                                        <ListItemAvatar>
                                            <Box sx={{ width: 40, height: 40 }} display="flex" justifyContent="center" alignItems="center">
                                                <Avatar
                                                    alt={notiItem.title ? notiItem.title : ''}
                                                    src={notiItem.avatar ? notiItem.avatar.toString().trim() : undefined}
                                                    sx={{ width: 30, height: 30, background: '#d0eaff' }}
                                                />
                                            </Box>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    component="div"
                                                    variant="subtitle1"
                                                    sx={{ fontSize: 16 }}
                                                    dangerouslySetInnerHTML={{ __html: notiItem.title ? notiItem.title : '' }}
                                                ></Typography>
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            <Grid container justifyContent="flex-end">
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" display="block" gutterBottom>
                                                        {notiItem.time ? notiItem.time : ''}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    {getGridReactNode(notiItem)}
                                </ListItemWrapper>
                                <Divider />
                            </React.Fragment>
                        );
                    })
                ) : (
                    <React.Fragment></React.Fragment>
                )}
            </List>
        </React.Fragment>
    );
};

export default NotificationList;
