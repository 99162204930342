import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import TrackChangesOutlinedIcon from '@mui/icons-material/TrackChangesOutlined';
import { FormattedMessage } from 'react-intl';
// import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const salesManager = {
    id: 'sales',
    title: <FormattedMessage id="sales" />,
    type: 'collapse',
    icon: CrisisAlertIcon,
    children: [
        {
            id: 'target',
            title: <FormattedMessage id="target" />,
            type: 'item',
            url: '/target/list',
            icon: TrackChangesOutlinedIcon,
        },
        // {
        //     id: 'leads',
        //     title: <FormattedMessage id="leads" />,
        //     type: 'item',
        //     url: '/leads/list',
        //     icon: AccountBoxOutlinedIcon,
        // },
        {
            id: 'deals',
            title: <FormattedMessage id="deals" />,
            type: 'item',
            url: '/deal/list',
            icon: AttachMoneyOutlinedIcon,
        },
    ],
};

export default salesManager;
