import React from "react";

// material-ui
import { Button, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
// assets
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/styles";
import { useLead } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "store";
import { setTaskItem } from "store/slices/task";
import Swal from "sweetalert2";
import { ITask } from "types/i-task";
import axios from "utils/axios";
import NotAnswering from "views/lead/popup/NotAnswering";
import TargetChooseFrm from "views/lead/popup/TargetChooseFrm";
import ReviewEdit from "views/target/_SellerModule/ReviewEdit";
import { ShowAlert } from "views/utilities/Alert";
import TaskFrm from "./TaskFrm";
import { END_POINT, ROUTE_NAME } from "configs";

interface HowApproachDialogProps {
	onCloseHowApproachDialog: () => void;
	isOpenHowApproachDialog: boolean;
	taskItem: ITask | null;
	point_type_sub_first?: string;
}
function HowApproachDialog({ onCloseHowApproachDialog, isOpenHowApproachDialog, taskItem, point_type_sub_first }: HowApproachDialogProps) {
	const { t } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isOpenTaskFrm, setOpenTaskFrm] = React.useState<boolean>(false);
	const [getTypeTask, setTypeTask] = React.useState<string>("meeting");
	const [stepNumber, setTaskStepNumber] = React.useState<number>(0);
	const [currentStepData, setCurrentStepData] = React.useState<any | null>(null);
	const [openNotAnswering, setOpenNotAnswering] = React.useState(false);
	const [openChooseTarget, setOpenChooseTarget] = React.useState(false);
	const [openAddTarget, setOpenAddTarget] = React.useState(false);
	const [isOpenAddTask, setOpenAddTask] = React.useState(false);
	const { fetchLeadDetail, onUpdateStepData } = useLead();
	const [pointType, setPointType] = React.useState<string>("");
	const [pointTypeSubFirst, setPointTypeSubFirst] = React.useState<string>("");
	const [pointTypeSubSecond, setPointTypeSubSecond] = React.useState<string>("");
	const [nextStepAddTask, setNextStepAddTask] = React.useState<string>("");
	const handleNotAnswering = async () => {
		let leadId: number = taskItem && taskItem.lead && taskItem.lead.id ? taskItem.lead.id : 0;
		let urlNavigate: string = "";
		if (taskItem && taskItem.type) {
			switch (taskItem.type) {
				case "task":
					if (taskItem.task_perform === "call") {
						urlNavigate = `${ROUTE_NAME.LEAD_UPDATE}/${leadId}?point_type=make_call&point_type_sub_first=not_answering`;
					}
					break;
				case "meeting":
					if (point_type_sub_first) {
						urlNavigate = `${ROUTE_NAME.LEAD_UPDATE}/${leadId}?point_type=meeting&point_type_sub_first=${point_type_sub_first}&point_type_sub_second=lead_not_turn_up`;
					}
					break;
			}
		}
		onCloseHowApproachDialog();
		navigate(urlNavigate);
	};
	const onYesCatchUpWithLead = async () => {
		if (nextStepAddTask === "") {
		} else {
			navigate(`${ROUTE_NAME.TARGET_KABAN}/${currentStepData?.goal?.id}`);
		}
	};
	const handleCloseAddtarget = () => {
		setOpenAddTarget(false);
	};

	const handleCheckPoint = async (point_type: any = "make_call", point_type_sub_first: any = null, point_type_sub_second: any = null) => {
		let leadId: number = taskItem && taskItem.lead && taskItem.lead.id ? taskItem.lead.id : 0;
		let params: any = {
			lead_id: leadId,
			point_type: point_type,
			point_type_sub_first: point_type_sub_first
		};
		const actionUrl = `${END_POINT.API_LEAD_MAKE_POINT}`;
		if (point_type === "make_call" && point_type_sub_first !== null) {
			const res = await axios.post(actionUrl, params, { headers: { isShowLoading: false } });
			const { status, data } = res.data;
			if (status) {
				return true;
			}
		}

		if (point_type === "meeting" && point_type_sub_second !== null) {
			params.point_type_sub_second = point_type_sub_second;
			// params.deal_type = deal_type;
			const actionUrl = `${END_POINT.API_LEAD_MAKE_POINT}`;
			const res = await axios.post(actionUrl, params, { headers: { isShowLoading: false } });
			const { status, data } = res.data;
			if (status) {
				return true;
			}
		}
	};

	const handleCloseChooseTarget = () => {
		setOpenChooseTarget(false);
	};

	const NotTurnUp = async () => {
		if (taskItem && taskItem.type) {
			switch (taskItem.type) {
				case "task":
					if (taskItem.task_perform === "call") {
						handleCheckPoint("make_call", "not_answering");
					}
					break;
				case "meeting":
					handleCheckPoint("meeting", point_type_sub_first ? point_type_sub_first : "", "lead_not_turn_up");
					break;
			}
		}
		setOpenNotAnswering(true);
		onCloseHowApproachDialog();
	};

	const leadNotTurnUp = async (_rejected: number, params: any) => {
		try {
			let leadId: number = taskItem && taskItem.lead && taskItem.lead.id ? taskItem.lead.id : 0;
			if (_rejected === 2) {
				params.step = -1;
				setCurrentStepData(await onUpdateStepData(leadId, params));
				navigate(`${ROUTE_NAME.TARGET_KABAN}/${currentStepData?.goal?.id}`);
			} else {
				if (params.rejected === 0) {
					await onUpdateStepData(leadId, params);
					Swal.fire({
						title: `<h6 class="step-title-content color-primary m-1">${t("Do you want to add task with this lead")}?</h6>`,
						denyButtonText: t("No"),
						confirmButtonText: t("Yes"),
						showDenyButton: true,
						showCloseButton: true
					}).then(async (result) => {
						if (result.isConfirmed === true) {
							setOpenAddTask(true);
						}
					});
				} else if (params.rejected === 1) {
					Swal.fire({
						title: `<h6 class="step-title-content color-primary m-1">${t("Have you had the target")}?</h6>`,
						confirmButtonText: t("Already"),
						denyButtonText: t("Not yet"),
						showDenyButton: true,
						showCloseButton: true
					}).then(async (result2) => {
						if (result2.isConfirmed === true) {
							// await onUpdateStepData(leadId, values);
							setOpenChooseTarget(true);
						}
						if (result2.isDenied === true) {
							// await onUpdateStepData(leadId, values);
							setOpenAddTarget(true);
						}
					});
				}
			}
		} catch (err: any) {
			console.log(err);
			ShowAlert(err?.message ?? t("error_system"), "error");
		}
	};

	const handleReject = async () => {
		let leadId: number = taskItem && taskItem.lead && taskItem.lead.id ? taskItem.lead.id : 0;
		let urlNavigate: string = "";
		if (taskItem && taskItem.type) {
			switch (taskItem.type) {
				case "task":
					if (taskItem.task_perform === "call") {
						handleCheckPoint("make_call", "rejected");
						urlNavigate = `${ROUTE_NAME.LEAD_UPDATE}/${leadId}?task_status=not_now`;
					}
					break;
				case "meeting":
					if (point_type_sub_first) {
						handleCheckPoint("meeting", `${point_type_sub_first}`, "rejected");
						urlNavigate = `${ROUTE_NAME.LEAD_UPDATE}/${leadId}?task_status=not_now`;
					}
					break;
			}
		}
		onCloseHowApproachDialog();
		navigate(urlNavigate);
	};
	const handleGotAppointment = async () => {
		setTypeTask("meeting");
		let itemStep: any = {
			id: taskItem && taskItem.lead && taskItem.lead.id ? taskItem.lead.id : 0,
			name: taskItem && taskItem.lead && taskItem.lead.name ? taskItem.lead.name : "",
			goal: {
				id: taskItem && taskItem.goal && taskItem.goal.id ? taskItem.goal.id : 0,
				name: taskItem && taskItem.goal && taskItem.goal.name ? taskItem.goal.name : ""
			}
		};
		if (taskItem && taskItem.type) {
			switch (taskItem.type) {
				case "task":
					if (taskItem.task_perform === "call") {
						setPointType("make_call");
						setPointTypeSubFirst("appointment_made");
					}
					break;
				case "meeting":
					setPointType("meeting");
					setPointTypeSubFirst(point_type_sub_first ? point_type_sub_first : "");
					setPointTypeSubSecond("need_1_more_appointment");
					break;
			}
		}
		setCurrentStepData(itemStep);
		setTaskStepNumber(4);
		setOpenTaskFrm(true);
		onCloseHowApproachDialog();
	};
	const onClickGotDeal = async () => {
		let leadId: number = taskItem && taskItem.lead && taskItem.lead.id ? taskItem.lead.id : 0;
		let urlNavigate: string = "";
		if (taskItem && taskItem.type) {
			switch (taskItem.type) {
				case "task":
					if (taskItem.task_perform === "call") {
						urlNavigate = `${ROUTE_NAME.LEAD_UPDATE}/${leadId}?point_type=make_call&point_type_sub_first=deal_made`;
					}
					break;
				case "meeting":
					if (point_type_sub_first) {
						urlNavigate = `${ROUTE_NAME.LEAD_UPDATE}/${leadId}?point_type=meeting&point_type_sub_first=${point_type_sub_first}&point_type_sub_second=deal_made`;
					}
					break;
			}
		}
		onCloseHowApproachDialog();
		navigate(urlNavigate);
	};
	const handleCloseTaskFrm = React.useCallback(() => {
		dispatch(setTaskItem({ taskItem: null }));
		setOpenTaskFrm(false);
	}, []);

	const clearOrNot = async (targetId: string) => {
		let leadId: number = taskItem && taskItem.lead && taskItem.lead.id ? taskItem.lead.id : 0;
		let params = {
			rejected: 1,
			step: -1,
			next_target: targetId,
			is_clear: 2,
			rejected_step: currentStepData?.step
		};
		let tmp: any = await onUpdateStepData(leadId, params);
		setCurrentStepData(tmp);
		handleCloseAddtarget();
		Swal.fire({
			title: `<h6 class="step-title-content color-primary m-1">${t("Do you want to add task with this lead")}?</h6>`,
			denyButtonText: t("No"),
			confirmButtonText: t("Yes"),
			showDenyButton: true,
			showCloseButton: true
		}).then(async (result) => {
			if (result.isConfirmed === true) {
				setOpenAddTask(true);
			}
			if (result.isDenied === true) {
				navigate(`${ROUTE_NAME.TARGET_KABAN}/${tmp?.goal?.id}`);
			}
		});
		setNextStepAddTask("goToKaban");
	};

	React.useEffect(() => {
		// let itemStep: any = {
		//     id: taskItem && taskItem.lead && taskItem.lead.id ? taskItem.lead.id : 0,
		//     name: taskItem && taskItem.lead && taskItem.lead.name ? taskItem.lead.name : '',
		//     step: taskItem && taskItem.lead && taskItem.lead?.step ? taskItem.lead?.step : '',
		//     goal: {
		//         id: taskItem && taskItem.goal && taskItem.goal.id ? taskItem.goal.id : 0,
		//         name: taskItem && taskItem.goal && taskItem.goal.name ? taskItem.goal.name : '',
		//     },
		// };
		// setCurrentStepData(itemStep);
		const getCurrentStepData = async () => {
			if (taskItem?.lead?.id) {
				setCurrentStepData(await fetchLeadDetail(taskItem.lead.id as any));
			}
		};
		getCurrentStepData();
	}, []);

	return (
		<React.Fragment>
			<Dialog
				open={isOpenHowApproachDialog}
				onClose={onCloseHowApproachDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				sx={{
					"&>div:nth-of-type(3)": {
						"&>div": {
							maxWidth: 500,
							width: 500
						}
					}
				}}
			>
				{isOpenHowApproachDialog && (
					<React.Fragment>
						<DialogTitle>
							<Grid textAlign="right" sx={{ pb: 1 }}>
								<Button
									onClick={onCloseHowApproachDialog}
									variant="contained"
									sx={{
										borderRadius: "50%",
										padding: "6px 6px 6px 6px",
										minWidth: "auto",
										background: "#E94D64",
										"&:hover": {
											background: "#ef1f3e"
										}
									}}
								>
									<CloseIcon sx={{ fontSize: "15px" }} />
								</Button>
							</Grid>
							<div className="step-title-content color-primary">{t("How was your approach?")}</div>
						</DialogTitle>
						<DialogContent>
							<div
								className="card-item m-1 cursor-poiter d-block"
								onClick={async () => {
									await NotTurnUp();
								}}
							>
								<h6 className="tittle_primary">{t("Lead not turn up")}</h6>
							</div>
							<div className="card-item m-1 cursor-poiter d-block" onClick={handleReject}>
								<h6 className="tittle_primary">{t("Rejected")}</h6>
							</div>
							<div className="card-item m-1 cursor-poiter d-block" onClick={handleGotAppointment}>
								{/* <h6 className="tittle_primary">{t('Need 1 more appoitment')}</h6> */}
								<h6 className="tittle_primary">{t("Appointment made")}</h6>
							</div>
							<div className="card-item m-1 cursor-poiter d-block" onClick={onClickGotDeal}>
								<h6 className="tittle_primary">{t("Deal made")}</h6>
							</div>
						</DialogContent>
					</React.Fragment>
				)}
			</Dialog>
			<NotAnswering
				open={openNotAnswering}
				handleCloseDialog={() => setOpenNotAnswering(false)}
				currentStepData
				callback={(callbackData) => {
					let params = {
						step: currentStepData?.step,
						rejected: callbackData,
						rejected_step: currentStepData?.step
					};
					try {
						leadNotTurnUp(callbackData, params);
					} catch (err: any) {
						console.log(err);
					}
				}}
			/>
			<TargetChooseFrm
				isOpenTargetChooseFrm={openChooseTarget}
				closeTargetChooseFrm={handleCloseChooseTarget}
				getTargetSelected={() => {
					console.log("fetch data");
				}}
				callback={async (targetIem: any) => {
					clearOrNot(targetIem.id);
				}}
				multiple={false}
			/>
			<ReviewEdit
				open={openAddTarget}
				id={0}
				createInLead={true}
				handleCloseDialog={handleCloseAddtarget}
				setRefreshData={() => {
					console.log("fetch data");
				}}
				callback={async (targetIem: any) => {
					clearOrNot(targetIem.id);
				}}
			/>
			<TaskFrm
				isOpenTaskFrm={isOpenAddTask}
				closeTaskFrm={() => setOpenAddTask(false)}
				currentStepData={currentStepData}
				callback={async (createTaskSuccess) => {
					if (createTaskSuccess) {
						await onYesCatchUpWithLead();
						setCurrentStepData(await fetchLeadDetail(currentStepData?.id));
					}
				}}
				point_type={pointType}
				point_type_sub_first={pointTypeSubFirst}
				point_type_sub_second={pointTypeSubSecond}
			/>
			<TaskFrm
				isOpenTaskFrm={isOpenTaskFrm}
				closeTaskFrm={handleCloseTaskFrm}
				typeTask={getTypeTask}
				stepNumber={stepNumber}
				currentStepData={currentStepData ? currentStepData : null}
				callback={async (createTaskSuccess) => {
					if (currentStepData?.step != 0 || currentStepData?.step != -1) {
						return;
					}
					let params = {
						completed_step: currentStepData?.completed_step + 1,
						step: currentStepData?.step + 1
					};
					let leadId: number = taskItem && taskItem.lead && taskItem.lead.id ? taskItem.lead.id : 0;
					setCurrentStepData(await onUpdateStepData(leadId, params));
				}}
				point_type={pointType}
				point_type_sub_first={pointTypeSubFirst}
				point_type_sub_second={pointTypeSubSecond}
			/>
		</React.Fragment>
	);
}
export default React.memo(HowApproachDialog);
