import React, { useState } from 'react';

// material-ui
import { useTheme, styled, Theme } from '@mui/material/styles';
import {
    Box,
    CardContent,
    ClickAwayListener,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Popper,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import Picker, { IEmojiData, SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';

// project imports
import UserDetails from './UserDetails';
import ChatDrawer from './ChatDrawer';
import ChartHistory from './ChartHistory';
import AvatarStatus from './AvatarStatus';
import { openDrawer } from 'store/slices/menu';
import MainCard from 'ui-component/cards/MainCard';
import Avatar from 'ui-component/extended/Avatar';
import { appDrawerWidth as drawerWidth, gridSpacing } from 'store/constant';
import { UserProfile } from 'types/user-profile';
import { useDispatch, useSelector } from 'store';
import { getUser, getUserChats, insertChat, getChatMessage } from 'store/slices/chat';

// assets
import AttachmentTwoToneIcon from '@mui/icons-material/AttachmentTwoTone';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import VideoCallTwoToneIcon from '@mui/icons-material/VideoCallTwoTone';
import CallTwoToneIcon from '@mui/icons-material/CallTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import MoodTwoToneIcon from '@mui/icons-material/MoodTwoTone';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';

// types
import { History as HistoryProps } from 'types/chat';
import useAuth from 'hooks/useAuth';
import { collection, getDocs, addDoc, doc, setDoc, Timestamp, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from 'configs/firebase';

const avatarImage = require.context('assets/images/users', true);

// drawer content element
const Main = styled('main', { shouldForwardProp: (prop: string) => prop !== 'open' })(
    ({ theme, open }: { theme: Theme; open: boolean }) => ({
        flexGrow: 1,
        paddingLeft: open ? theme.spacing(3) : 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter,
        }),
        marginLeft: `-${drawerWidth}px`,
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 0,
            marginLeft: 0,
        },
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.shorter,
            }),
            marginLeft: 0,
        }),
    })
);

// ==============================|| APPLICATION CHAT ||============================== //

const ChatMainPage = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('lg'));
    const { user } = useAuth();
    const dispatch = useDispatch();

    const [path, setPath] = React.useState('');

    // handle right sidebar dropdown menu
    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClickSort = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleCloseSort = () => {
        setAnchorEl(null);
    };

    // set chat details page open when user is selected from sidebar
    const [emailDetails, setEmailDetails] = React.useState(false);
    const handleUserChange = (event: React.SyntheticEvent) => {
        setEmailDetails((prev) => !prev);
    };

    // toggle sidebar
    const [openChatDrawer, setOpenChatDrawer] = React.useState(true);
    const handleDrawerOpen = () => {
        setOpenChatDrawer((prevState) => !prevState);
    };

    // close sidebar when widow size below 'md' breakpoint
    React.useEffect(() => {
        setOpenChatDrawer(!matchDownSM);
    }, [matchDownSM]);

    const [toUser, setToUser] = useState<UserProfile>({});
    const [data, setData] = React.useState<HistoryProps[]>([]);
    const chatState = useSelector((state) => state.chat);

    // React.useEffect(() => {
    //     setUser(chatState.user);
    // }, [chatState.user]);

    React.useEffect(() => {
        if (!path.length) {
            return;
        }
        const unsubscribe = onSnapshot(doc(db, 'messages', path), (docSnap) => {
            if (docSnap.exists()) {
                const messageList = Object.values(docSnap.data()).sort((a, b) => a.time - b.time);
                if (messageList.length > 1) {
                    setData(messageList);
                }
                // getChatMessage(messageList);
            }
        });
        return unsubscribe;
    }, [data, path]);

    React.useEffect(() => {
        // hide left drawer when email app opens
        dispatch(openDrawer(false));
    }, []);

    // Chỗ này xử lý nhấn chọn user
    React.useEffect(() => {
        if (user && user.id && user.id > 0) {
            if (user.id < (toUser?.id as number)) {
                setPath(`${user?.id}-${toUser.id}`);
            } else {
                setPath(`${toUser.id}-${user?.id}`);
            }
        }
        // if (user && user.id && toUser.id) {
        //     dispatch(getUserChats(user?.id as number, toUser.id as number));
        // }
    }, [user, toUser]);

    // handle new message form
    const [message, setMessage] = useState('');

    const handleOnSend = async () => {
        if (!path.length) {
            return;
        }
        const timestamp = Timestamp.now().toMillis();
        setMessage('');
        const newMessage = {
            from: user?.id,
            to: toUser.id,
            text: message,
            time: timestamp,
        };
        // setData((prevState) => [...prevState, newMessage]);
        await setDoc(doc(db, 'messages', path), { [`${timestamp}`]: newMessage }, { merge: true });
        // setData((prevState) => [...prevState, newMessage]);
        // dispatch(insertChat(newMessage, user?.id as number, toUser.id as number));
    };

    const handleEnter = (event: React.KeyboardEvent<HTMLDivElement> | undefined) => {
        if (event?.key !== 'Enter') {
            return;
        }
        handleOnSend();
    };

    // handle emoji
    const onEmojiClick = (event: React.MouseEvent<Element, MouseEvent>, emojiObject: IEmojiData) => {
        setMessage(message + emojiObject.emoji);
    };

    const [anchorElEmoji, setAnchorElEmoji] = React.useState<any>(); /** No single type can cater for all elements */
    const handleOnEmojiButtonClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        setAnchorElEmoji(anchorElEmoji ? null : event?.currentTarget);
    };

    const emojiOpen = Boolean(anchorElEmoji);
    const emojiId = emojiOpen ? 'simple-popper' : undefined;
    const handleCloseEmoji = () => {
        setAnchorElEmoji(null);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <ChatDrawer openChatDrawer={openChatDrawer} handleDrawerOpen={handleDrawerOpen} setUser={setToUser} />
            <Main theme={theme} open={openChatDrawer}>
                {toUser?.id && (
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs zeroMinWidth sx={{ display: emailDetails ? { xs: 'none', sm: 'flex' } : 'flex' }}>
                            <MainCard
                                sx={{
                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50',
                                }}
                            >
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12}>
                                        <Grid container alignItems="center" spacing={0.5}>
                                            <Grid item>
                                                <IconButton onClick={handleDrawerOpen} size="large">
                                                    <MenuRoundedIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <Grid container spacing={2} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                                                    <Grid item>
                                                        <Avatar
                                                            alt={toUser.name}
                                                            // src={toUser.avatar && avatarImage(`./${toUser.avatar}`).default}
                                                        >
                                                            {toUser.name?.slice(0, 1)}
                                                        </Avatar>
                                                    </Grid>
                                                    <Grid item sm zeroMinWidth>
                                                        <Grid container spacing={0} alignItems="center">
                                                            <Grid item xs={12}>
                                                                <Typography variant="h4" component="div">
                                                                    {toUser.name}{' '}
                                                                    {toUser.online_status && <AvatarStatus status={toUser.online_status} />}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle2">Last seen {toUser.lastMessage}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item sm zeroMinWidth />
                                            <Grid item>
                                                <IconButton size="large">
                                                    <CallTwoToneIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton size="large">
                                                    <VideoCallTwoToneIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton onClick={handleUserChange} size="large">
                                                    <ErrorTwoToneIcon />
                                                </IconButton>
                                            </Grid>
                                            {/* <Grid item>
                                                <IconButton onClick={handleClickSort} size="large">
                                                    <MoreHorizTwoToneIcon />
                                                </IconButton>
                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleCloseSort}                                                    
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <MenuItem onClick={handleCloseSort}>Name</MenuItem>
                                                    <MenuItem onClick={handleCloseSort}>Date</MenuItem>
                                                    <MenuItem onClick={handleCloseSort}>Ratting</MenuItem>
                                                    <MenuItem onClick={handleCloseSort}>Unread</MenuItem>
                                                </Menu>
                                            </Grid> */}
                                        </Grid>
                                        <Divider sx={{ mt: theme.spacing(2) }} />
                                    </Grid>
                                    <PerfectScrollbar
                                        style={{ width: '100%', height: 'calc(100vh - 440px)', overflowX: 'hidden', minHeight: 525 }}
                                    >
                                        <CardContent>
                                            <ChartHistory theme={theme} user={toUser} data={data} />
                                        </CardContent>
                                    </PerfectScrollbar>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item>
                                                <IconButton
                                                    ref={anchorElEmoji}
                                                    aria-describedby={emojiId}
                                                    onClick={handleOnEmojiButtonClick}
                                                    size="large"
                                                >
                                                    <MoodTwoToneIcon />
                                                </IconButton>
                                                <Popper
                                                    id={emojiId}
                                                    open={emojiOpen}
                                                    anchorEl={anchorElEmoji}
                                                    disablePortal
                                                    popperOptions={{
                                                        modifiers: [
                                                            {
                                                                name: 'offset',
                                                                options: {
                                                                    offset: [-20, 20],
                                                                },
                                                            },
                                                        ],
                                                    }}
                                                >
                                                    <ClickAwayListener onClickAway={handleCloseEmoji}>
                                                        <>
                                                            {emojiOpen && (
                                                                <MainCard elevation={8} content={false}>
                                                                    <Picker
                                                                        onEmojiClick={onEmojiClick}
                                                                        skinTone={SKIN_TONE_MEDIUM_DARK}
                                                                        disableAutoFocus
                                                                    />
                                                                </MainCard>
                                                            )}
                                                        </>
                                                    </ClickAwayListener>
                                                </Popper>
                                            </Grid>
                                            <Grid item xs zeroMinWidth>
                                                <TextField
                                                    fullWidth
                                                    label="Type a Message"
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    onKeyPress={handleEnter}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <IconButton color="primary" onClick={handleOnSend} size="large">
                                                    <SendTwoToneIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                        {emailDetails && (
                            <Grid item sx={{ margin: { xs: '0 auto', md: 'initial' } }}>
                                <Box sx={{ display: { xs: 'block', sm: 'none', textAlign: 'right' } }}>
                                    <IconButton onClick={handleUserChange} sx={{ mb: -5 }} size="large">
                                        <HighlightOffTwoToneIcon />
                                    </IconButton>
                                </Box>
                                <UserDetails user={toUser} />
                            </Grid>
                        )}
                    </Grid>
                )}
            </Main>
        </Box>
    );
};

export default ChatMainPage;
