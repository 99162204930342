import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    IconButton,
    Paper,
    Popper,
    Stack,
    Typography,
    Link,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import User1 from 'assets/images/users/user-round.svg';
import useAuth from 'hooks/useAuth';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconLogout, IconSettings } from '@tabler/icons';
import { END_POINT, NOTIFY_NAME, ROUTE_NAME } from 'configs';
import useConfig from 'hooks/useConfig';
import { FormattedMessage } from 'react-intl';
import axios from 'utils/axios';
import LightTooltip from 'ui-component/extended/Tooltips';
import { openDrawer } from 'store/slices/menu';
import { openSnackbar } from 'store/slices/snackbar';
import { _fetchMenu } from 'store/slices/fetch';
import { useDispatch, useSelector } from 'store';
import { useTranslation } from 'react-i18next';
import ListAccount from './ListAccount';
import { useParseUserInfo } from 'views/utilities/format';
import auth_service from 'utils/authService';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const { logout } = useAuth();
    const [open, setOpen] = useState(false);

    const { user } = useAuth();

    const [totalGroup, setTotalGroup] = useState<number>(0);
    const [firstItem, setFirstItem] = useState<any>(null);
    const [listItemGroup, setListItemGroup] = useState<any>(null);

    const { isFetch } = useSelector((state) => state.fetch);
    const [isShowListGroup, setShowListGroup] = useState<boolean>(false);

    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const anchorRef = useRef<any>(null);

    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };
    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number, route: string = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };

    // handle switch group
    async function swiffRoleAPI(_group_id: number) {
        try {
            const res = await axios.post(END_POINT.API_GROUP_SWITCH, { id: _group_id });
            const { status, data } = res.data;
            if (status) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: `${t('You are active under the account')} ${data.items?.current_group_name ?? data.items.name}`,
                        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                        variant: 'alert',
                        alert: {
                            color: 'success',
                        },
                        close: false,
                    })
                );
                // let userData = { ...data.items, name: data.items?.current_group_name ?? data.items.name, avatar: data?.items.current_avatar ?? data.items.avatar };
                // setUser(userData);
                dispatch(_fetchMenu(!isFetch));
                navigate(`${ROUTE_NAME.DASHBOARD}`);
                // window.location.href = `${ROUTE_NAME.DASHBOARD}`;
            }
        } catch (err: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: err?.data?.message ? err.data.message : t('error_system'),
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    variant: 'alert',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            );
        }
    }

    const getCurrentGroupInfo = async () => {
        const res: any = await axios.get(END_POINT.API_GROUP_LIST, {}).then((result) => {
            const { status, data } = result.data;
            if (status) {
                setTotalGroup(data.items.length);
                // == 1 ? data.items.length : data.items.length - 1
                setFirstItem(data.items[0]);
                // .filter((item: any) => item.id != user?.current_group)[0]
                setListItemGroup(data.items);
                // .filter((item: any) => item.id != user?.current_group)
            }
        });
    }
    // close
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleToggleListAccount = () => {
        setShowListGroup((prev) => !prev);
    };
    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const prevOpen = useRef(open);

    useEffect(() => {
        getCurrentGroupInfo();
    }, [isFetch]);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.success.main,
                        background: `${theme.palette.success.main}!important`,
                        color: theme.palette.success.light,
                        '& svg': {
                            stroke: theme.palette.success.light,
                        },
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0,
                    },
                }}
                icon={
                    <Avatar
                        src={user && user.avatar ? user.avatar : ''}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer',
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={<IconSettings stroke={1.5} size="24px" color={theme.palette.success.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={open} {...TransitionProps}>
                            <Paper>
                                {open && isShowListGroup === false && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Box sx={{ p: 2, pb: 1, mb: 2, boxShadow: '1px 3px 20px #ccc' }}>
                                            <Stack direction="row" justifyContent="space-between">
                                                <Stack>
                                                    <Stack direction="row" spacing={0.5} alignItems="center">
                                                        <Avatar
                                                            src={user && user.avatar ? user.avatar : ''}
                                                            sx={{
                                                                ...theme.typography.mediumAvatar,
                                                                marginRight: '1rem !important',
                                                                cursor: 'pointer',
                                                            }}
                                                            color="inherit"
                                                        />
                                                        <Typography component="span" variant="h4">
                                                            {user && user.name ? user.name : ''}
                                                        </Typography>
                                                    </Stack>
                                                    {user?.current_group == null && (
                                                        <Typography variant="subtitle2" mt={1}>{user && user.email ? user.email : ''}</Typography>
                                                    )}
                                                </Stack>
                                                <Stack justifyContent="center" alignItems="center"
                                                    sx={{
                                                        position: 'relative',
                                                        '& .change': {
                                                            position: 'absolute',
                                                            top: 'calc(100% / 2 - 1rem)',
                                                            left: '0',
                                                            transform: "rotate(0deg)",
                                                            transition: 'all 500ms linear',

                                                        },
                                                        '&:hover .change': {
                                                            transform: "rotate(180deg)"
                                                        },
                                                    }}>
                                                    {firstItem != null && (
                                                        <>
                                                            <svg className="change" fill="currentColor" viewBox="0 0 20 20" width="2rem" height="2rem"><g fillRule="evenodd" transform="translate(-446 -398)"><g fillRule="nonzero"><path d="M96.628 206.613A7.97 7.97 0 0 1 96 203.5a7.967 7.967 0 0 1 2.343-5.657A7.978 7.978 0 0 1 104 195.5a7.978 7.978 0 0 1 5.129 1.86.75.75 0 0 0 .962-1.15A9.479 9.479 0 0 0 104 194a9.478 9.478 0 0 0-6.717 2.783A9.467 9.467 0 0 0 94.5 203.5a9.47 9.47 0 0 0 .747 3.698.75.75 0 1 0 1.381-.585zm14.744-6.226A7.97 7.97 0 0 1 112 203.5a7.967 7.967 0 0 1-2.343 5.657A7.978 7.978 0 0 1 104 211.5a7.978 7.978 0 0 1-5.128-1.86.75.75 0 0 0-.962 1.152A9.479 9.479 0 0 0 104 213a9.478 9.478 0 0 0 6.717-2.783 9.467 9.467 0 0 0 2.783-6.717 9.47 9.47 0 0 0-.747-3.698.75.75 0 1 0-1.381.585z" transform="translate(352 204.5)"></path><path d="M109.5 197h-2.25a.75.75 0 1 0 0 1.5h3a.75.75 0 0 0 .75-.75v-3a.75.75 0 1 0-1.5 0V197zm-11 13h2.25a.75.75 0 1 0 0-1.5h-3a.75.75 0 0 0-.75.75v3a.75.75 0 1 0 1.5 0V210z" transform="translate(352 204.5)"></path></g></g></svg>
                                                            <LightTooltip title={firstItem?.name}>
                                                                <IconButton
                                                                    size="small"
                                                                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                                    onClick={() => swiffRoleAPI(firstItem.id)}
                                                                >
                                                                    <Avatar
                                                                        src={firstItem && firstItem.avatar ? firstItem.avatar : ''}
                                                                        sx={{
                                                                            ...theme.typography.mediumAvatar,
                                                                            cursor: 'pointer',
                                                                            width: 22,
                                                                            height: 22,
                                                                        }}
                                                                        color="inherit"
                                                                    />
                                                                </IconButton>
                                                            </LightTooltip>
                                                        </>
                                                    )}

                                                </Stack>
                                            </Stack>
                                            <Divider />
                                            <Stack
                                                sx={{
                                                    mt: 1,
                                                    mb: 1,
                                                    pl: 1,
                                                    pr: 1,
                                                    borderRadius: '8px',
                                                    '&:hover': {
                                                        background: theme.palette.success.light
                                                    },
                                                }}>
                                                {totalGroup > 1 && (
                                                    <Link onClick={handleToggleListAccount}>
                                                        <Typography className="d-flex justify-space-between w-100" variant="subtitle2" pt={1} pb={1}>
                                                            <Stack>
                                                                {t("Show all groups")}
                                                            </Stack>
                                                            <Chip color="error" label={totalGroup} size="small" />
                                                        </Typography>
                                                    </Link>
                                                )}
                                            </Stack>
                                        </Box>
                                        <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                            <Box sx={{ p: 2, pt: 0 }}>
                                                <List
                                                    component="nav"
                                                    sx={{
                                                        width: '100%',
                                                        maxWidth: 350,
                                                        minWidth: 300,
                                                        backgroundColor: theme.palette.background.paper,
                                                        borderRadius: '10px',
                                                        [theme.breakpoints.down('md')]: {
                                                            minWidth: '100%',
                                                        },
                                                        '& .MuiListItemButton-root': {
                                                            mt: 0.5,
                                                        },
                                                    }}
                                                >
                                                    <ListItemButton
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 1}
                                                        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                                                            handleListItemClick(event, 0, ROUTE_NAME.SETTING)
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <IconSettings stroke={1.5} size="20px" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2">
                                                                    <FormattedMessage id="account_setting" />
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                    <ListItemButton
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 4}
                                                        onClick={handleLogout}
                                                    >
                                                        <ListItemIcon>
                                                            <IconLogout stroke={1.5} size="20px" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2">
                                                                    <FormattedMessage id="logout" />
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </List>
                                            </Box>
                                        </PerfectScrollbar>
                                    </MainCard>
                                )}
                                {open && isShowListGroup === true && (
                                    <ListAccount
                                        listItemGroup={listItemGroup}
                                        closeModal={() => setShowListGroup(false)}
                                        callback={(item: any) => {
                                            setShowListGroup(false);
                                            setOpen(false);
                                            // setUser(item);
                                        }}
                                    />
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
