// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Card, CardContent, CardMedia, Divider, Grid, Typography } from '@mui/material';

// project imports
import AvatarStatus from './AvatarStatus';
import SubCard from 'ui-component/cards/SubCard';
import { gridSpacing } from 'store/constant';
import { UserProfile } from 'types/user-profile';

// assets
import PinDropTwoToneIcon from '@mui/icons-material/PinDropTwoTone';
import PhoneTwoToneIcon from '@mui/icons-material/PhoneTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';

import images1 from 'assets/images/pages/img-catalog1.png';
import images2 from 'assets/images/pages/img-catalog2.png';
import images3 from 'assets/images/pages/img-catalog3.png';
import { t } from 'i18next';

const avatarImage = require.context('assets/images/users', true);

// ==============================|| USER PROFILE / DETAILS ||============================== //

interface UserDetailsProps {
    user: UserProfile;
}

const UserDetails = ({ user }: UserDetailsProps) => {
    const theme = useTheme();

    return (
        <Grid container spacing={gridSpacing} sx={{ width: '100%', maxWidth: 300 }}>
            <Grid item xs={12}>
                <Card>
                    <CardContent
                        sx={{
                            textAlign: 'center',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {/* {user.profi ? (
                                    <Avatar
                                        alt={user.name}
                                        src={user.avatar && avatarImage(`./${user.avatar}`).default}
                                        sx={{
                                            m: '0 auto',
                                            width: 130,
                                            height: 130,
                                            border: '1px solid',
                                            borderColor: theme.palette.primary.main,
                                            p: 1,
                                            bgcolor: 'transparent',
                                        }}
                                    />
                                ) : (
                                    <Avatar>{user.name && user.name.slice(0, 1).toUpperCase()}</Avatar>
                                )} */}
                                <Avatar>{user.name && user.name.slice(0, 1).toUpperCase()}</Avatar>
                            </Grid>
                            <Grid item xs={12}>
                                <AvatarStatus status={user.online_status!} />
                                <Typography variant="caption" component="div">
                                    {/* {user?.online_status!.replaceAll('_', ' ')} */}user?.online_status
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="div">
                                    {user.name}
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Typography variant="body2" component="div">
                                    {user.role}user?.role
                                </Typography>
                            </Grid> */}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <SubCard
                    sx={{
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5" component="div">
                                {t('Information')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                {/* <Grid item xs={12}>
                                    <Typography variant="body2">
                                        <PinDropTwoToneIcon sx={{ verticalAlign: 'sub', fontSize: '1.125rem', mr: 0.625 }} /> {user.billing_address}
                                        Parkways, U.S
                                    </Typography>
                                </Grid> */}
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        <PhoneTwoToneIcon sx={{ verticalAlign: 'sub', fontSize: '1.125rem', mr: 0.625 }} /> {user.phone}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        <EmailTwoToneIcon sx={{ verticalAlign: 'sub', fontSize: '1.125rem', mr: 0.625 }} />
                                        {user.email}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
        </Grid>
    );
};

export default UserDetails;
