// third-party
import { FormattedMessage } from 'react-intl';

import { IconChartDonut2 } from '@tabler/icons';
import { ROUTE_NAME } from 'configs';

const commissionSeller = {
    id: 'commission-seller',
    title: <FormattedMessage id="commission" />,
    type: 'group',
    url: ROUTE_NAME.COMMISSION_DETAIL,
    icon: IconChartDonut2,
    breadcrumbs: true,
};

export default commissionSeller;
