// routing
// auth provider
import NavigationScroll from "layout/NavigationScroll";
import Routes from "routes";
import ThemeCustomization from "themes";
import Snackbar from "ui-component/extended/Snackbar";
// project imports
import LoadingSpinner from "ui-component/LoadingSpinner";
import Locales from "ui-component/Locales";
import Notify from "ui-component/Notify";
import RTLLayout from "ui-component/RTLLayout";
import { JWTProvider as AuthProvider } from "contexts/JWTContext";
import AlertDialog from "views/ui-elements/advance/UIDialog/AlertDialog";
// import { hot } from 'react-hot-loader';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';
// ==============================|| APP ||============================== //

const App = () => (
	<ThemeCustomization>
		{/* RTL layout */}
		<RTLLayout>
			<Locales>
				<NavigationScroll>
					<AuthProvider>
						<>
							<Routes />
							<Snackbar />
						</>
					</AuthProvider>
				</NavigationScroll>
				<LoadingSpinner />
				<Notify />
				<AlertDialog />
			</Locales>
		</RTLLayout>
	</ThemeCustomization>
);

export default App;
