// third-party
import { FormattedMessage } from 'react-intl';

// assets
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { ROUTE_NAME } from 'configs';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const chat = {
    id: 'chat',
    title: <FormattedMessage id="chat" />,
    type: 'group',
    url: ROUTE_NAME.CHAT,
    icon: ChatBubbleOutlineOutlinedIcon,
    breadcrumbs: true,
};

export default chat;
