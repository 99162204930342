export const NOTIFY_NAME = {
	NOTI_TYPE_SUCCESS: "success",
	NOTI_TYPE_WARNING: "warning",
	NOTI_TYPE_DANGER: "danger",
	NOTI_SAVE_SUCCESSFULLY: "Save successfully",
	NOTI_SAVE_FAIL: "Save failed",
	NOTI_DELETE_SUCCESSFULLY: "Delete successfully",
	NOTI_DELETE_FAIL: "Delete failed",
	NOTI_PERMISSION_DENINED: "Permission denined",
	NOTI_CONFIRMED_DELETE: "Do you want to delete ?",
	NOTI_WRONG_USERNAME: "Wrong username",
	NOTI_WRONG_PASSWORD: "Wrong password"
};
