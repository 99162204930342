// third-party
import { FormattedMessage } from 'react-intl';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import RememberMeOutlinedIcon from '@mui/icons-material/RememberMeOutlined';
import { ROUTE_NAME } from 'configs';

const productService = {
    id: 'product-service',
    title: <FormattedMessage id="product_service" />,
    type: 'group',
    url: ROUTE_NAME.PRODUCT_SERVICE_LIST,
    icon: StorefrontOutlinedIcon,
    breadcrumbs: true,
};

export default productService;
