import React from "react";

// material-ui
import { Avatar, Box, Button, Checkbox, Dialog, FormControlLabel } from "@mui/material";
// assets
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/styles";
import { END_POINT } from "configs";
import { useTranslation } from "react-i18next";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import { ITask } from "types/i-task";
import axios from "utils/axios";
import HowApproachDialog from "./HowApproachDialog";
import HowMeetDialog from "./HowMeetDialog";

const label = { inputProps: { "aria-label": "Go to next step" } };
interface DoneConfirmedDialogProps {
	onCloseDoneConfirmedDialog: () => void;
	isOpenDoneConfirmedDialog: boolean;
	taskId: number;
	perpage?: number;
	onSetTaskData?: (dataType: string, keyword: string, val: number) => void;
	dataType: string;
}
interface ITaskChangedStatus {
	id: number[];
	status: string;
}
function DoneConfirmedDialog({
	onCloseDoneConfirmedDialog,
	isOpenDoneConfirmedDialog,
	taskId,
	perpage,
	onSetTaskData,
	dataType
}: DoneConfirmedDialogProps) {
	const { t } = useTranslation();
	const theme = useTheme();
	const dispatch = useDispatch();
	const [taskItem, setTaskItem] = React.useState<ITask | null>(null);
	const [isOpenHowApproachDialog, setOpenHowApproachDialog] = React.useState<boolean>(false);
	const [isOpenHowMeetDialog, setOpenHowMeetDialog] = React.useState<boolean>(false);
	let mounted = true;
	React.useEffect(() => {
		const init = async () => {
			if (taskId > 0) {
				const rsTaskDetail: any = await axios.get(`${END_POINT.API_TASK_SHOW_DETAIL}/${taskId}`, {
					headers: { isShowLoading: true }
				});
				if (mounted) {
					const { status, message, data } = rsTaskDetail.data;
					if (status) {
						let item: ITask | null = data.items ? data.items : null;
						setTaskItem(item);
					} else {
						dispatch(
							openSnackbar({
								open: true,
								message,
								anchorOrigin: { vertical: "bottom", horizontal: "left" },
								variant: "alert",
								alert: {
									color: "error"
								},
								transition: "Fade",
								close: false
							})
						);
					}
				}
			}
		};
		init();
		return () => {
			mounted = false;
		};
	}, [taskId]);
	const handleConfirmedDone = async () => {
		let txtMsg = "";
		let typeMsg = "error";
		let checked = true;
		let res: any = null;
		try {
			if (checked) {
				let ids: number[] = [];
				ids.push(taskId);
				let dataSaved: ITaskChangedStatus = {
					id: ids,
					status: "is_completed"
				};
				res = await axios.put(`${END_POINT.API_TASK_UPDATE_TASK}`, dataSaved);
				const { status } = res.data;
				if (status) {
					typeMsg = "success";
					txtMsg = t("done_task_successfully");
					if (onSetTaskData && perpage) {
						onSetTaskData(dataType, "", perpage);
					}
					onCloseDoneConfirmedDialog();
					if (taskItem && taskItem.task_perform === "call") {
						setOpenHowApproachDialog(true);
					} else {
						setOpenHowMeetDialog(true);
					}
				}
			}
		} catch (err: any) {
			if (err?.data?.message) {
				txtMsg = err.data.message;
			} else if (err?.message) {
				txtMsg = err.message;
			} else {
				txtMsg = t("error_system");
			}
		}
		dispatch(
			openSnackbar({
				open: true,
				message: txtMsg,
				anchorOrigin: { vertical: "bottom", horizontal: "left" },
				variant: "alert",
				alert: {
					color: typeMsg
				},
				transition: "Fade",
				close: false
			})
		);
	};
	const handleCloseHowApproachDialog = () => {
		setOpenHowApproachDialog(false);
	};
	const handleCloseHowMeetDialog = () => {
		setOpenHowMeetDialog(false);
	};
	return (
		<React.Fragment>
			<Dialog
				open={isOpenDoneConfirmedDialog}
				onClose={onCloseDoneConfirmedDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				sx={{
					"&>div:nth-of-type(3)": {
						"&>div": {
							maxWidth: 500,
							width: 500
						}
					}
				}}
			>
				{isOpenDoneConfirmedDialog && (
					<Box sx={{ p: 3 }}>
						<Box display="flex" justifyContent="flex-end">
							<Button
								onClick={onCloseDoneConfirmedDialog}
								variant="contained"
								sx={{
									borderRadius: "50%",
									padding: "6px 6px 6px 6px",
									minWidth: "auto",
									background: "#E94D64",
									"&:hover": {
										background: "#ef1f3e"
									}
								}}
							>
								<CloseIcon sx={{ fontSize: "15px" }} />
							</Button>
						</Box>
						<Box sx={{ fontSize: "20px", fontWeight: 800, color: theme.palette.primary.main, textAlign: "center", mt: 2 }}>
							{t("done_task_confirmed")}
						</Box>
						<Box sx={{ textAlign: "center", mt: 2 }}>{t("you_have_finished_this_task")}</Box>
						<Box className="card-item m-1">
							<Box
								sx={{
									py: 1,
									px: 1
								}}
							>
								<Box className="tittle_primary">{taskItem && taskItem.title ? taskItem.title : ""}</Box>
								{taskItem?.lead && (
									<Box className="sub-tittle_primary">
										<Avatar
											src={taskItem && taskItem.avatar ? taskItem.avatar : ""}
											sx={{
												background: "transparent",
												width: 30,
												height: 30,
												cursor: "pointer"
											}}
										/>
										<Box> {taskItem && taskItem.lead && taskItem.lead.name ? taskItem.lead.name : ""}</Box>
									</Box>
								)}
							</Box>
						</Box>
						<Box sx={{ mt: 2 }} display="flex" justifyContent="left" columnGap={1}>
							{/* <label for="swal2-checkbox-next" class="swal2-checkbox">
                                <input type="checkbox" value="1" id="swal2-checkbox-next">
                                    <span className="swal2-label tittle_primary">${t("Go to next step")}</span>
                            </label> */}
							{/* <FormControlLabel
                                label={t("Go to next step")}
                                control={<Checkbox checked={false} onChange={()=>{}} />}
                            /> */}
						</Box>
						<Box sx={{ mt: 2 }} display="flex" justifyContent="center" columnGap={1}>
							<Button
								variant="contained"
								onClick={() => {
									onCloseDoneConfirmedDialog();
								}}
								sx={{
									backgroundColor: theme.palette.success.light,
									color: theme.palette.primary.main,
									"&:hover": {
										color: theme.palette.success.light,
										backgroundColor: theme.palette.secondary.main
									}
								}}
							>
								{t("no_confirm")}
							</Button>
							<Button
								variant="contained"
								onClick={handleConfirmedDone}
								sx={{
									color: theme.palette.success.light,
									backgroundColor: theme.palette.primary.main,
									"&:hover": {
										color: theme.palette.success.light,
										backgroundColor: theme.palette.secondary.main
									}
								}}
							>
								{t("yes_confirm")}
							</Button>
						</Box>
					</Box>
				)}
			</Dialog>
			<HowApproachDialog
				onCloseHowApproachDialog={handleCloseHowApproachDialog}
				isOpenHowApproachDialog={isOpenHowApproachDialog}
				taskItem={taskItem}
			/>
			<HowMeetDialog onCloseHowMeetDialog={handleCloseHowMeetDialog} isOpenHowMeetDialog={isOpenHowMeetDialog} taskItem={taskItem} />
		</React.Fragment>
	);
}
export default React.memo(DoneConfirmedDialog);
