// third-party
// assets
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { FormattedMessage } from 'react-intl';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { ROUTE_NAME } from 'configs';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const contact = {
    id: 'contact',
    title: <FormattedMessage id="contact" />,
    type: 'collapse',
    icon: GroupOutlinedIcon,
    children: [
        {
            id: 'person',
            title: <FormattedMessage id="person" />,
            type: 'item',
            url: ROUTE_NAME.PERSONAL_LIST,
            icon: PersonOutlinedIcon,
        },
        {
            id: 'company',
            title: <FormattedMessage id="company" />,
            type: 'item',
            url: ROUTE_NAME.COMPANY_LIST,
            icon: ApartmentOutlinedIcon,
        },
    ],
};

export default contact;
