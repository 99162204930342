import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

// material-ui
import {
	Avatar,
	Box,
	Chip,
	ClickAwayListener,
	Divider,
	Grid,
	Paper,
	Popper,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
	CardActions,
	Button
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import NotificationList from "./NotificationList";

// assets
import { IconBell } from "@tabler/icons";
import { realtimeDb } from "configs/firebase";
import { onValue, ref } from "firebase/database";
import TaskFrm from "views/task/TaskFrm";

import { END_POINT } from "configs";
import useAuth from "hooks/useAuth";
import useConfig from "hooks/useConfig";
import { useTranslation } from "react-i18next";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import { setTaskItem } from "store/slices/task";
import INotification from "types/i-notification";
import { UserProfile } from "types/user-profile";
import axios from "utils/axios";
import ChangeDeadlineConfirmedDialog from "views/task/ChangeDeadlineConfirmedDialog";
import { ITask } from "types/i-task";
import HowApproachDialog from "views/task/HowApproachDialog";
import AskTypeMeeting from "views/lead/popup/AskTypeMeeting";
import DoneConfirmedDialog from "views/task/DoneConfirmedDialog";
// notification status options
const status = [
	{
		value: "all",
		label: "All Notification"
	},
	{
		value: "new",
		label: "New"
	},
	{
		value: "unread",
		label: "Unread"
	},
	{
		value: "other",
		label: "Other"
	}
];

// ==============================|| NOTIFICATION ||============================== //
interface IMarkReadSaved {
	id?: string | null;
	mark_all: boolean;
}
interface IRealTimeSaved {
	id: string;
	is_read_realtime: boolean;
}
interface IViewNotificationSaved {
	is_viewed_notification: boolean;
}
interface ITaskChangedStatus {
	id: number[];
	status: string;
}
const NotificationSection = () => {
	const theme = useTheme();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { locale } = useConfig();
	const { user }: any = useAuth();
	const matchesXs = useMediaQuery(theme.breakpoints.down("md"));
	const [isOpenTaskFrm, setOpenTaskFrm] = useState<boolean>(false);
	const [open, setOpen] = useState(false);
	const [total, setTotal] = useState<number>(0);
	const [notificationLst, setNotificationLst] = useState<INotification[]>([]);
	const [newNotification, setNewNotification] = useState<boolean>(false);
	const [isOpenChangeDeadlineConfirmedDialog, setOpenChangeDeadlineConfirmedDialog] = useState<boolean>(false);
	const [taskId, setTaskId] = useState<number>(0);
	const [isOpenHowApproachDialog, setOpenHowApproachDialog] = useState<boolean>(false);
	const [taskItem, setTaskItemHowApproach] = useState<ITask | null>(null);
	const [notiId, setNotiId] = useState<string>("");
	const [isOpenAskTypeMeeting, setOpenAskTypeMeeting] = useState<boolean>(false);
	const [pointTypeSubFirst, setPointTypeSubFirst] = useState<string>("");
	var mounted: boolean = true;
	const [perpage, setPerpage] = useState<number>(10);
	const notificationRef = ref(realtimeDb, "notifications");
	const [isOpenDoneConfirmedDialog, setOpenDoneConfirmedDialog] = useState<boolean>(false);

	/**
	 * anchorRef is used on different componets and specifying one type leads to other components throwing an error
	 * */
	const anchorRef = useRef<any>(null);

	const handleToggle = async () => {
		let dataSaved: IViewNotificationSaved = {
			is_viewed_notification: true
		};
		const res = await axios.put(END_POINT.API_NOTIFICATION_UPDATE_IS_VIEWED_RED, dataSaved);
		setNewNotification(false);
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const prevOpen = useRef(open);
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}
		prevOpen.current = open;
	}, [open]);
	useEffect(() => {
		const init = async () => {
			const rs: any = await axios.get(END_POINT.API_GET_USER_INFO);
			if (mounted) {
				const { status, data } = rs.data;
				if (status) {
					let userInfo: UserProfile = data.items;
					let isNewNotification = false;
					if ((userInfo.is_viewed_notification as Boolean) === false) {
						isNewNotification = true;
					}
					setNewNotification(isNewNotification);
				}
			}
		};
		init();
		return () => {
			mounted = false;
		};
	}, []);
	const loadNotificationLst = async () => {
		try {
			if (open) {
				const rsNoti: any = await axios.get(END_POINT.API_GET_NOTIFICATION, { params: { item: perpage } });
				if (mounted) {
					const { status, data } = rsNoti.data;
					if (status) {
						let notificationData: INotification[] = data.items ? data.items : [];
						let totalItem: number = notificationData.length;
						setNotificationLst(notificationData);
						setTotal(totalItem);
					}
				}
			}
		} catch (err: any) {
			dispatch(
				openSnackbar({
					open: true,
					message: err?.data?.message ? err.data.message : t("error_system"),
					anchorOrigin: { vertical: "bottom", horizontal: "left" },
					variant: "alert",
					alert: {
						color: "error"
					},
					transition: "Fade",
					close: false
				})
			);
		}
	};
	useEffect(() => {
		loadNotificationLst();
		return () => {
			mounted = false;
		};
	}, [perpage, open]);
	const handleOpenTaskFrm = () => {
		setOpenTaskFrm(true);
	};
	const handleCloseTaskFrm = useCallback(() => {
		dispatch(setTaskItem({ taskItem: null }));
		setOpenTaskFrm(false);
	}, []);
	const handleMarkAsAllRead = async () => {
		try {
			let data: IMarkReadSaved = {
				id: null,
				mark_all: true
			};
			const res: any = await axios.put(`${END_POINT.API_NOTIFICATION_MARK_READ}`, data);
			const { status } = res.data;
			if (status) {
				let notificationData: INotification[] = res.data.data.items ? res.data.data.items : [];
				let totalItem: number = notificationData.length;
				loadNotificationLst();
				setTotal(totalItem);
				setNewNotification(false);
			}
		} catch (err: any) {
			dispatch(
				openSnackbar({
					open: true,
					message: err?.data?.message ? err.data.message : t("error_system"),
					anchorOrigin: { vertical: "bottom", horizontal: "left" },
					variant: "alert",
					alert: {
						color: "error"
					},
					close: false
				})
			);
		}
	};
	useEffect(() => {
		const subscriber = onValue(notificationRef, async (snapshot) => {
			const notiArray: any = Object.values(snapshot.val());
			const notiLength = notiArray.filter((item: any) => item.user_id === user.id).length;
			if (notiLength > 0) {
				setTimeout(async () => {
					const res = await axios.get(END_POINT.API_GET_NOTIFICATION, { params: { item: perpage } });
					const { data } = res.data;
					let notifiLst: INotification[] = data.items && data.items.length > 0 ? data.items : [];
					let totalItem: number = notifiLst.length;
					setNotificationLst(notifiLst);
					setTotal(totalItem);
					if (notifiLst.length > 0) {
						if (notifiLst[0].is_read === false && notifiLst[0].is_read_realtime === false) {
							setNewNotification(true);
							if (notifiLst[0].type == "remind_task_meeting" || notifiLst[0].type == "change_deadline_task") {
								setTaskId(notifiLst[0].object_id);
								setOpenDoneConfirmedDialog(true);
							} else {
								dispatch(
									openSnackbar({
										open: true,
										title: notifiLst[0].title,
										message: notifiLst[0].message,
										anchorOrigin: { vertical: "bottom", horizontal: "left" },
										variant: "noti",
										alert: {
											color: "success"
										},
										close: false,
										item: notifiLst[0]
									})
								);
							}
							let dataRealTime: IRealTimeSaved = {
								id: notifiLst[0].id,
								is_read_realtime: true
							};
							console.log(notifiLst);
							const resShotRealtime: any = await axios.put(END_POINT.API_NOTIFICATION_UPDATE_REALTIME, dataRealTime);
						}
					}
				}, 5000);
			}
		});
		return subscriber;
	}, []);
	const handleCloseDoneConfirmedDialog = useCallback(() => {
		setOpenDoneConfirmedDialog(false);
	}, []);

	const handleConfirmTaskDone = async (notiItem: INotification) => {
		let taskId: number = notiItem && notiItem.object_id ? parseInt(notiItem.object_id.toString()) : 0;
		const rsTaskDetail = await axios.get(`${END_POINT.API_TASK_SHOW_DETAIL}/${taskId}`, { headers: { isShowLoading: true } });
		const { status, message, data } = rsTaskDetail.data;
		if (status) {
			let itemTask: ITask | null = data.items ? data.items : null;
			if (itemTask) {
				let ids: number[] = [];
				ids.push(taskId);
				let dataSaved: ITaskChangedStatus = {
					id: ids,
					status: "is_completed"
				};
				setTaskItemHowApproach(itemTask);
				switch (itemTask.type) {
					case "task":
						if (itemTask.task_perform === "call") {
							const rsUpdateTask: any = await axios.put(`${END_POINT.API_TASK_UPDATE_TASK}`, dataSaved, {
								headers: { isShowLoading: true }
							});
							setOpenHowApproachDialog(true);
						}
						break;
					case "meeting":
						setOpenAskTypeMeeting(true);
						break;
				}
				loadNotificationLst();
				dispatch(
					openSnackbar({
						open: true,
						message: t("done_task_successfully"),
						anchorOrigin: { vertical: "bottom", horizontal: "left" },
						variant: "alert",
						alert: {
							color: "success"
						},
						transition: "Fade",
						close: false
					})
				);
			}
		} else {
			dispatch(
				openSnackbar({
					open: true,
					message,
					anchorOrigin: { vertical: "bottom", horizontal: "left" },
					variant: "alert",
					alert: {
						color: "error"
					},
					transition: "Fade",
					close: false
				})
			);
		}
	};
	const handleConfirmTaskNotYet = (notiItem: INotification) => {
		let objectId: number = notiItem && notiItem.object_id ? parseInt(notiItem.object_id.toString()) : 0;
		setTaskId(objectId);
		setNotiId(notiItem.id);
		setOpenChangeDeadlineConfirmedDialog(true);
	};
	const handleAfterAskTypeMeeting = async (callbackData: any) => {
		const actionUrl = `${END_POINT.API_TASK_UPDATE_TASK}`;
		let params = {
			id: taskItem && taskItem.id ? (taskItem.id as any) : null,
			status: "is_completed",
			meeting_type: callbackData
		};
		const res = await axios.put(actionUrl, params, { headers: { isShowLoading: false } });
		if (res.status) {
			switch (parseInt(callbackData)) {
				case 0:
					setPointTypeSubFirst("initial_meeting");
					break;
				case 1:
					setPointTypeSubFirst("closing_meeting");
					break;
				case 2:
					setPointTypeSubFirst("2_in_1");
					break;
			}
			setOpenHowApproachDialog(true);
		}
	};
	const handleCloseChangeDeadlineConfirmedDialog = useCallback(async () => {
		setOpenChangeDeadlineConfirmedDialog(false);
	}, []);
	const handleCloseHowApproachDialog = useCallback(() => {
		setOpenHowApproachDialog(false);
	}, []);
	const handleViewMore = () => {
		setPerpage((prevState) => prevState + 10);
	};
	return (
		<>
			<Box
				sx={{
					ml: 2,
					mr: 3,
					[theme.breakpoints.down("md")]: {
						mr: 2
					},
					position: "relative"
				}}
			>
				<Avatar
					variant="rounded"
					sx={{
						...theme.typography.commonAvatar,
						...theme.typography.mediumAvatar,
						transition: "all .2s ease-in-out",
						background: theme.palette.mode === "dark" ? theme.palette.dark.main : theme.palette.success.light,
						color: theme.palette.mode === "dark" ? theme.palette.warning.dark : theme.palette.success.dark,
						'&[aria-controls="menu-list-grow"],&:hover': {
							background: theme.palette.mode === "dark" ? theme.palette.warning.dark : theme.palette.success.dark,
							color: theme.palette.mode === "dark" ? theme.palette.grey[800] : theme.palette.success.light
						},
						overflow: "visible",
						width: 45,
						height: 45
					}}
					ref={anchorRef}
					aria-controls={open ? "menu-list-grow" : undefined}
					aria-haspopup="true"
					onClick={handleToggle}
					color="inherit"
				>
					{newNotification && (
						<Box
							sx={{
								width: "8px",
								height: "8px",
								position: "absolute",
								top: "-3px",
								right: "-1px",
								background: theme.palette.error.main,
								borderRadius: "50%"
							}}
						></Box>
					)}

					<IconBell stroke={1.5} size={25} />
				</Avatar>
			</Box>

			<Popper
				placement={matchesXs ? "bottom" : "bottom-end"}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				popperOptions={{
					modifiers: [
						{
							name: "offset",
							options: {
								offset: [matchesXs ? 5 : 0, 20]
							}
						}
					]
				}}
			>
				{({ TransitionProps }) => (
					<ClickAwayListener onClickAway={handleClose}>
						<Transitions position={matchesXs ? "top" : "top-right"} in={open} {...TransitionProps}>
							<Paper>
								{open && (
									<MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
										<Grid container direction="column" spacing={2}>
											<Grid item xs={12}>
												<Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
													<Grid item>
														<Stack direction="row" spacing={2}>
															<Typography variant="subtitle1">{t("all_notification")}</Typography>
															<Chip
																size="small"
																label={total}
																sx={{
																	color: theme.palette.background.default,
																	bgcolor: theme.palette.warning.dark
																}}
															/>
														</Stack>
													</Grid>
													<Grid item>
														<Typography
															component={Link}
															to="#"
															variant="subtitle2"
															color="primary"
															onClick={handleMarkAsAllRead}
														>
															{t("mark_as_all_read")}
														</Typography>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<PerfectScrollbar
													style={{ height: "100%", maxHeight: "calc(100vh - 205px)", overflowX: "hidden" }}
												>
													<NotificationList
														notificationLst={notificationLst}
														loadNotificationLst={loadNotificationLst}
														onToggle={handleToggle}
														onOpenTaskFrm={handleOpenTaskFrm}
														onConfirmTaskDone={handleConfirmTaskDone}
														onConfirmTaskNotYet={handleConfirmTaskNotYet}
													/>
												</PerfectScrollbar>
											</Grid>
										</Grid>
										<Divider />
										<CardActions sx={{ p: 1.25, justifyContent: "center" }}>
											<Button size="small" disableElevation onClick={handleViewMore}>
												{t("view_more")}
											</Button>
										</CardActions>
									</MainCard>
								)}
							</Paper>
						</Transitions>
					</ClickAwayListener>
				)}
			</Popper>
			<TaskFrm isOpenTaskFrm={isOpenTaskFrm} closeTaskFrm={handleCloseTaskFrm} />
			<ChangeDeadlineConfirmedDialog
				onCloseChangeDeadlineConfirmedDialog={handleCloseChangeDeadlineConfirmedDialog}
				isOpenChangeDeadlineConfirmedDialog={isOpenChangeDeadlineConfirmedDialog}
				taskId={taskId}
				notiId={notiId}
				loadNotificationLst={loadNotificationLst}
			/>
			<HowApproachDialog
				onCloseHowApproachDialog={handleCloseHowApproachDialog}
				isOpenHowApproachDialog={isOpenHowApproachDialog}
				taskItem={taskItem}
				point_type_sub_first={pointTypeSubFirst}
			/>
			<AskTypeMeeting
				open={isOpenAskTypeMeeting}
				handleCloseDialog={() => setOpenAskTypeMeeting(false)}
				callback={(callBackData) => {
					handleAfterAskTypeMeeting(callBackData);
				}}
			/>
			<DoneConfirmedDialog
				onCloseDoneConfirmedDialog={handleCloseDoneConfirmedDialog}
				isOpenDoneConfirmedDialog={isOpenDoneConfirmedDialog}
				taskId={taskId}
				dataType={"today"}
			/>
		</>
	);
};

export default NotificationSection;
