import { END_POINT } from 'configs';
import React from 'react';
import { ITargetAnalysis, IMTargetAnalysis } from 'types/target';
import axiosServices from 'utils/axios';

export const useTarget = () => {
    const [analysisData, setAnalysisData] = React.useState<ITargetAnalysis>();
    const [mAnalysisData, setMAnalysisData] = React.useState<IMTargetAnalysis>();

    const fetchTargetAnalysis = async (goalId: number) => {
        const res = await axiosServices.get(END_POINT.API_TARGET_ANALYSIS, {
            params: {
                goal_id: goalId,
            },
        });
        const { status, data } = res.data;
        if (status) {
            setAnalysisData(data.items);
            setMAnalysisData(data.items);
        }
    };
    return { analysisData, mAnalysisData, fetchTargetAnalysis };
};
