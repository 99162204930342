export const END_POINT = {
	URL_SERVER: process.env.REACT_APP_URL,
	API_ENDPOINT: process.env.REACT_APP_URL + "/api",
	PER_PAGE_SELECTED: 10,
	PER_PAGE_SELECTED_v2: 12,
	NUMBER_ROWS: [10, 15, 25, 50, 100, 0],
	NUMBER_ROWS_v2: [12, 16, 32, 64, 128, 0],
	MAX_ROWS_PERPAGE: 999999999,
	CURRENCY_LIST: ["USD", "VND"],
	DEBOUNCED_TIMEOUT: 300,
	API_LOGIN: "/login",
	API_AUTHENTICATED: "/checkValidToken",
	API_LOGOUT: "/logout",
	API_PRODUCT_SERVICES_LIST: "/products-services",
	API_PRODUCT_SERVICES_CREATE: "/product-service/create",
	API_PRODUCT_SERVICES_SHOW: "/product-service/show",
	API_PRODUCT_SERVICES_UPDATE: "/product-service/update",
	API_PRODUCT_SERVICES_DELETE: "/product-service/delete",
	API_PRODUCT_SERVICES_FAB_UPDATE: "/products-service/update-FAB",
	API_TARGET_LIST: "/goals",
	API_TARGET_CREATE: "/goal/create",
	API_TARGET_UPDATE: "/goal/update",
	API_TARGET_SHOW: "/goal/show",
	API_TARGET_QUESTION: "/questions",
	API_TARGET_ANALYSIS: "/goal/analysis",
	API_TARGET_PARENT_LIST: "/goal/list-parent-goal",
	API_REMOVE_TARGET: "/goal/delete",
	API_GET_TAG_LIST: "/get-goal-tag",
	API_GET_TARGET_LIST_NOT_PANGATION: "/goal/list-without-paginate",
	API_TARGET_GET_PRODUCT_SERVICE_LIST: "/goal/product-services",
	API_PRESENTATION_UPLOAD_FILES: "/product-service/upload",
	API_PRESENTATION_GET_FILES: "/product-service/files",
	API_PRESENTATION_DELETE_FILES: "/product-service/delete-files",
	API_NOTE_LIST: "/product-service/notes",
	API_NOTE_CREATE: "/product-service/create-note",
	API_NOTE_UPDATE: "/product-service/update-note",
	API_NOTE_DELETE: "/product-service/delete-notes",
	API_LEAD_LIST: "/lead",
	API_LEAD_CREATE: "/lead/create",
	API_LEAD_SHOW: "/lead/show",
	API_LEAD_UPDATE: "/lead/update",
	API_LEAD_REMOVE: "/lead/remove",
	API_LEAD_FILES: "/lead/files",
	API_LEAD_DELETE_FILES: "lead/delete-files",
	API_LEAD_UPLOAD: "/lead/upload",
	API_LEAD_MAKE_POINT: "/lead/make-point",
	API_LEAD_GET_COMPANY_LIST: "/lead/get-companies",
	API_LEAD_UPLOAD_AVATAR: "/lead/upload-avatar",
	API_TEMPLATE_DROPDOWNLIST: "/product-service/scripts",
	API_TEMPLATE_SAVE_SCRIPT: "/product-service/scripts",
	API_ALL_CURRENCY: "/settings/get-currency",
	API_STEP_DELETE: "/product-service/step/delete",
	API_PRODUCT_SERVICE_CHANGE_FILENAME: "/product-service/change-name-file",
	API_PRODUCT_SERVICE_QUALIFY_LEAD: "/product-service/list-without-paginate",
	API_COMPANY_LIST: "/lead/get-company-leads",
	API_COMPANY_SHOW_DETAIL: "/lead/show-company-contact",
	API_COMPANY_DROPDOWNLST: "/lead/get-companies",
	API_COMPANY_CREATE: "/lead/get-company-leads/create",
	API_COMPANY_UPDATE: "/lead/get-company-leads/update",
	API_COMPANY_DELETE: "/lead/get-company-leads/update",
	API_PERSONAL_LIST: "/lead/get-personal-leads",
	API_PERSONAL_CREATE: "/lead/get-personal-leads",
	API_PERSONAL_UPDATE: "/lead/get-personal-leads",
	API_PERSONAL_DELETE: "/lead/get-personal-leads",
	API_PERSONAL_SHOW_DETAIL: "/lead/show-person-contact",
	API_CONTACT_CREATE: "/lead/create-customer",
	API_CONTACT_UPDATE: "/lead/update-customer",
	API_CONTACT_IMPORT: "/lead/import-contacts",
	API_CONTACT_FILE_DETAIL: "/lead/show-contact-file",
	API_CONTACT_DELETE: "/lead/delete-contact",
	API_CONTACT_SHOW_DETAIL: "/lead/show",
	API_SYNC_CONTACT: "/lead/sync-to-sellers",
	API_LEAD_UPDATE_JOURNAL_TITLE: "/lead/update-journey-title",
	API_TASK_LIST: "/task",
	API_TARGET_DROPDOWN_LIST: "/goal/list-without-paginate",
	API_TASK_CREATE: "/task/create",
	API_TASK_UPDATE: "/task/update",
	API_TASK_REMINDERS: "/task/get-reminders",
	API_TASK_SHOW_DETAIL: "/task/show",
	API_TASK_REMIND_MEETING: "/task/remind-meeting",
	API_TRACKING_CALL: "/task/tracking-is-called",
	API_TRACKING_MEETING: "/task/tracking-is-meeting",
	API_TASK_DELETE: "/task/remove",
	API_TASK_UPDATE_TASK: "/task/change-status",
	API_TASK_NO_PAGINATION: "/task/get-list-task-without-paginate",
	API_SCRIPT: "/scripts",
	API_TASK_LIST_BY_START_DATE: "/task/get-list-task-today-or-another-date",
	API_LEAD_DROPDOWNLIST: "/lead/get-list-lead-without-paginate",
	API_DEAL_CREATE: "/deal/create",
	API_DEAL_UPDATE: "/deal/update",
	API_DEAL_DELETE: "/deal/delete",
	API_DEAL_LIST: "/deals",
	API_TYPE_CONTACTS: "/type-contacts",
	API_KABANVIEW: "/lead/get-leads-kaban-view",
	API_CONTACT_DROPDOWN_LIST: "/lead/get-contacts-without-paginate",
	API_CONTACT_LIST: "/lead/get-contacts",
	API_LEAD_IMPORT_CONTACT: "/lead/import-contacts",
	API_LEAD_NOTE_CREATE: "/lead/create-lead-note",
	API_LEAD_NOTE_UPDATE: "/lead/update-lead-note",
	API_LEAD_NOTE_DELETE: "/lead/delete-lead-note",
	API_LEAD_REMOVE_AVATAR: "/lead/remove-avatar",
	//Dashboard API
	API_DASHBOARD_GET: "/dashboard",
	API_DASHBOARD_MANAGER_GET: "/manager/dashboard",
	API_DASHBOARD_GET_SALE_RESULT: "/dashboard/sale-result",
	API_DASHBOARD_GET_TASK: "/task",
	API_CONTACT_ADD_NOTE: "/lead/create-lead-note",
	API_CONTACT_UPDATE_NOTE: "/lead/update-lead-note",
	API_CONTACT_NOTE_LST: "/lead/get-notes",
	API_CONTACT_NOTE_DETAIL: "/lead/show-note",
	API_CONTACT_NOTE_DELETE: "/lead/delete-lead-note",
	API_CONTACT_FILE_LST: "/lead/get-contact-file",
	API_CONTACT_FILE_IMPORT: "/lead/upload-contact-file",
	API_CONTACT_FILE_DELETE: "/lead/delete-contact-file",

	//CHAT
	API_GET_USERS: "/users",
	API_UPDATE_INFORMATION: "/update-information",
	API_CHANGE_CURRENCY: "/change-currency",
	API_CHANGE_LANGUAGE: "/change-lanquage",
	API_SWITCH_ROLE: "account/switch-role",
	API_GET_SELLER_LIST: "/account/get-sellers",
	API_GET_SELLER_LIST_PAGINATE: "account/get-sellers-with-paginate",
	API_GET_SELLER_STATISTICS: "account/get-statistics-sellers",
	API_EXPORT_SELLER: "account/export-sellers",
	API_PRODUCT_SERVICE_SYNC_DATA: "/product-service/sync-data",
	API_SELLERS_WITH_PAGINATE: "/account/get-sellers-with-paginate",
	API_TYPE_DROP_DOWNLST: "/account/get-allocation-type",
	API_DEPARTMENT_DROPDOWNLST: "/account/get-list-departments",
	API_UPDATE_EXPECT_VALUE: "/account/update-expect-value",
	API_GET_USER_INFO: "/account",
	API_GET_NOTIFICATION: "/notifications",
	API_NOTIFICATION_MARK_READ: "/notification/mark-read",
	API_COMMISSION: "/commission",
	API_COMMISSION_LST: "/commission/list",
	API_NOTIFICATION_UPDATE_REALTIME: "/notification/update-realtime",
	API_NOTIFICATION_UPDATE_IS_VIEWED_RED: "/account/update-is-viewed-notifications",
	API_IMPORT_EXCEL: "/import/excel",
	API_LEAD_LST_IMPORTED: "/import/data",
	API_IMPORT_EXCEL_ADD_OPTION: "/import/add-option",
	API_IMPORT_EXCEL_DELETE: "/import/delete",
	API_IMPORT_CHECK_VALIDATE: "/import/check-validate",
	API_IMPORT_PROCCESS_TO_LEAD: "/import/process",
	/* --------------------------- Params save cookie --------------------------- */
	PARAMS_PANIGATION_COOKIE: {
		lead: "leads_list",
		target: "target_list",
		target_sellers: "target_sellers",
		target_lead_of_target: "target_lead_of_target",
		product_service_list: "product_service_list",
		company_list: "company_list",
		personal_list: "personal_list",
		task_list: "task_list",
		deal_list: "deal_list",
		seller_list: "seller_list",
		group_list: "group_list"
	},
	API_CREATE_MULTIPLE_LEAD_REFERALS: "/lead/create-multiple-lead-referals",
	API_IMPORT_CONTACT_EXCEL: "/import-contact/excel",
	API_IMPORT_CONTACT_DATA: "/import-contact/data",
	API_IMPORT_CONTACT_ADD_OPTION: "/import-contact/add-option",
	API_IMPORT_CONTACT_DELETE: "/import-contact/delete",
	API_IMPORT_CONTACT_VALIDATE: "/import-contact/check-validate",
	API_IMPORT_CONTACT_PROCESS: "/import-contact/process",
	API_IMPORT_PRODUCT_EXCEL: "/import-product",
	API_DELETE_NOTI: "/notification/delete",
	API_GROUP_LIST: "/groups",
	API_GROUP_SHOW: "/groups/detail",
	API_GROUP_CREATE: "/groups/create",
	API_GROUP_UPDATE: "/groups/update",
	API_GET_USER_OF_COMPANY: "/account/get-user-of-company",
	API_GET_GROUP_OF_COMPANY: "/account/get-group-of-company",
	API_GROUP_SWITCH: "/groups/switch",
	API_GROUP_INVITE: "/groups/invite",
	API_GROUP_ASSIGN_ROLE: "/groups/assign-role",
	// API_GROUP_REMOVE: '/groups',
	API_DUPLICATE_PRODUCT: "/product-service/duplicate",
	API_TASK_BY_DATE: "/task-by-date"
};
