export const ROUTE_NAME = {
	DASHBOARD: "/dashboard",
	TARGET: "/target/list",
	TARGET_CREATE: "/target/create",
	TARGET_EDIT: "/target/edit",
	TARGET_SELLER_CREATE: "/target/seller/create",
	TARGET_SELLER_EDIT: "/target/seller/edit",
	TARGET_KABAN: "/target/sale-circle",
	TARGET_ANALYSIS: "/target/analysis",
	TARGET_SELLER_LIST: "/target/sellers",
	TARGET_LEAD_LIST: "/target/lead-of-target",
	LEADS: "/leads/list",
	LEAD_CREATE: "/leads/create",
	LEAD_UPDATE: "/leads/update",
	LEAD_IMPORT: "/leads/import",
	PRODUCT_SERVICE_LIST: "/product-service/list",
	PRODUCT_SERVICE_ADD: "/product-service/add",
	PRODUCT_SERVICE_EDIT: "/product-service/edit",
	PRODUCT_SERVICE_IMPORT: "/product-service/import",
	COMPANY_LIST: "/company/list",
	COMPANY_ADD: "/company/add",
	COMPANY_EDIT: "/company/edit",
	PERSONAL_LIST: "/personal/list",
	PERSONAL_ADD: "/personal/add",
	PERSONAL_EDIT: "/personal/edit",
	PERSONAL_IMPORT: "/personal/import",
	CONTACT_DETAIL: "/contact/detail",
	CONTACT_IMPORT: "/contact/import",
	TASK_LIST: "/task/list",
	TASK_ADD: "/task/add",
	TASK_EDIT: "/task/edit",
	SERVICE: "/app/service",
	REPORT: "/app/report",
	SETTING: "/setting",
	DEAL_LIST: "/deal/list",
	CHAT: "/chat",
	SELLER_LIST: "/seller/list",
	PERMISSION_DENIED: "/permission-denied",
	PAGE_ERROR: "/pages/error",
	LEAD_CONTACTED_DETAIL: "/contacted-twelve-step",
	GROUP_LIST: "/group",
	COMMISSION_BOARD: "/commission/board",
	COMMISSION_DETAIL: "/commission/detail"
};
