// third-party
import { Chance } from "chance";
import { END_POINT } from "configs";
import useConfig from "hooks/useConfig";
import React, { createContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { store } from "store";
import accountReducer from "store/accountReducer";
// reducer - state management
import { LOGIN, LOGOUT } from "store/actions";
import { openSnackbar } from "store/slices/snackbar";
import { InitialLoginContextProps, JWTContextType } from "types/auth";
// project imports
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "store";
import Loader from "ui-component/Loader";
import auth_service from "utils/authService";
import axios from "utils/axios";

const chance = new Chance();

// constant
const initialState: InitialLoginContextProps = {
	isLoggedIn: false,
	isInitialized: false,
	user: null
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
	const [state, dispatch] = useReducer(accountReducer, initialState);
	const { onChangeLocale, onChangeCurrency, onChangeDateFormat } = useConfig();
	const { i18n, t } = useTranslation();
	const { isFetch } = useSelector((state) => state.fetch);
	const { search } = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		const init = async () => {
			let res: any = null;
			let checked = true;
			const accessToken: string = auth_service.getAccessToken();
			if (!accessToken) {
				checked = false;
			} else {
				res = await axios.post(END_POINT.API_AUTHENTICATED, { token: accessToken });
				const { status } = res;
				if (parseInt(status) !== 200) {
					checked = false;
				}
			}
			if (checked) {
				const { status, data } = res.data;
				if (status) {
					const { user } = data.data;
					// kai add
					let userData = {
						...user,
						name: user?.current_group != null ? user?.current_group_name : user?.name,
						avatar: user?.current_group != null ? user?.current_avatar : user.avatar
					};
					const lang: string = user && user.lang ? user.lang : "vi";
					i18n.changeLanguage(lang);
					dispatch({
						type: LOGIN,
						payload: {
							isLoggedIn: true,
							user: userData
						}
					});
				} else {
					delete axios.defaults.headers.common.Authorization;
					auth_service.clearAccessToken();
					dispatch({ type: LOGOUT });
				}
			} else {
				delete axios.defaults.headers.common.Authorization;
				auth_service.clearAccessToken();
				dispatch({ type: LOGOUT });
			}
		};
		init();
	}, [isFetch]);

	const getCurrencyIsoLangCode = (lang: string) => {
		let iso_lang_code = "";
		let dateFormat = "";
		switch (lang) {
			case "vi":
				iso_lang_code = "vi-VN";
				dateFormat = "dd/MM/yyyy";
				break;
			default:
				iso_lang_code = "en-US";
				dateFormat = "dd/MM/yyyy";
				break;
		}
		return { iso_lang_code, dateFormat };
	};
	const login = async (email: string, password: string) => {
		let txtMsg = "";
		let typeMsg = "error";
		try {
			const res: any = await axios.post(END_POINT.API_LOGIN, { email, password }, { headers: { isShowLoading: true } });
			const { status, message } = res.data;
			txtMsg = message;
			if (status) {
				typeMsg = "success";
				const { token, user } = res.data.data;
				// kai add
				let userData = {
					...user,
					name: user?.current_group != null ? user?.current_group_name : user?.name,
					avatar: user?.current_group != null ? user?.current_avatar : user.avatar
				};
				auth_service.setAccessToken(token);
				const lang: string = user && user.lang ? user.lang : "vi";
				const currency: string = user && user.currency ? user.currency : "VND";
				const dateFormat: string = getCurrencyIsoLangCode(lang).dateFormat;
				onChangeLocale(lang);
				onChangeCurrency(currency);
				onChangeDateFormat(dateFormat);
				i18n.changeLanguage(lang);
				dispatch({
					type: LOGIN,
					payload: {
						isLoggedIn: true,
						user: userData
					}
				});
			}
		} catch (err: any) {
			if (err?.data?.message) {
				txtMsg = err.data.message;
			} else if (err?.message) {
				txtMsg = err.message;
			} else {
				txtMsg = t("error_system");
			}
			store.dispatch(
				openSnackbar({
					open: true,
					message: txtMsg,
					anchorOrigin: { vertical: "bottom", horizontal: "left" },
					variant: "alert",
					alert: {
						color: typeMsg
					},
					transition: "Fade",
					close: false
				})
			);
		}
	};
	const loginByAuthGoogleCallback = async () => {
		const res = await axios.get(`/auth/google/callback${search}`);
		const { status } = res;
		if (status === 200) {
			const { status, data } = res.data;
			if (status) {
				const { lang, currency, token } = data;
				let userData = {
					...data,
					name: data?.current_group != null ? data?.current_group_name : data?.name,
					avatar: data?.current_group != null ? data?.current_avatar : data.avatar
				};
				const dateFormat: string = getCurrencyIsoLangCode(lang).dateFormat;
				auth_service.setAccessToken(token);
				onChangeLocale(lang);
				onChangeCurrency(currency);
				onChangeDateFormat(dateFormat);
				i18n.changeLanguage(lang);
				dispatch({
					type: LOGIN,
					payload: {
						isLoggedIn: true,
						user: userData
					}
				});
			} else {
				delete axios.defaults.headers.common.Authorization;
				auth_service.clearAccessToken();
				dispatch({ type: LOGOUT });
				store.dispatch(
					openSnackbar({
						open: true,
						message: t("login_failure"),
						anchorOrigin: { vertical: "bottom", horizontal: "left" },
						variant: "alert",
						alert: {
							color: "error"
						},
						transition: "Fade",
						close: false
					})
				);
				navigate("/login");
			}
		}
	};
	const register = async (email: string, password: string, firstName: string, lastName: string) => {
		// todo: this flow need to be recode as it not verified
		const id = chance.bb_pin();
		const response = await axios.post("/api/account/register", {
			id,
			email,
			password,
			firstName,
			lastName
		});
		let users = response.data;

		if (window.localStorage.getItem("users") !== undefined && window.localStorage.getItem("users") !== null) {
			const localUsers = window.localStorage.getItem("users");
			users = [
				...JSON.parse(localUsers!),
				{
					id,
					email,
					password,
					name: `${firstName} ${lastName}`
				}
			];
		}

		window.localStorage.setItem("users", JSON.stringify(users));
	};

	const logout = async () => {
		let txtMsg = "";
		let typeMsg = "error";
		try {
			const res = await axios.post(END_POINT.API_LOGOUT, undefined, { headers: { isShowLoading: true } });
			const { status } = res.data;
			if (status) {
				delete axios.defaults.headers.common.Authorization;
				auth_service.clearAccessToken();
				dispatch({ type: LOGOUT });
			}
		} catch (err: any) {
			if (err?.data?.message) {
				txtMsg = err.data.message;
			} else if (err?.message) {
				txtMsg = err.message;
			} else {
				txtMsg = t("error_system");
			}
			store.dispatch(
				openSnackbar({
					open: true,
					message: txtMsg,
					anchorOrigin: { vertical: "bottom", horizontal: "left" },
					variant: "alert",
					alert: {
						color: typeMsg
					},
					transition: "Fade",
					close: false
				})
			);
		}
	};

	const resetPassword = (email: string) => console.log(email);

	const updateProfile = () => {};

	if (state.isInitialized !== undefined && !state.isInitialized) {
		return <Loader />;
	}

	return (
		<JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile, loginByAuthGoogleCallback }}>
			{children}
		</JWTContext.Provider>
	);
};

export default JWTContext;
