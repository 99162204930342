import { forwardRef, ForwardRefExoticComponent, RefAttributes, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import useConfig from 'hooks/useConfig';
import { useDispatch, useSelector } from 'store';
import { activeItem, openDrawer, activeParentItem } from 'store/slices/menu';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// types
import { LinkTarget, NavItemType } from 'types';
import useAuth from 'hooks/useAuth';

interface NavItemProps {
    item: NavItemType;
    level: number;
}

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }: NavItemProps) => {
    const theme = useTheme();
    const { user } = useAuth();
    const { pathname } = useLocation();
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const { borderRadius } = useConfig();
    const dispatch = useDispatch();
    const { selectedItem, selectedParentItem } = useSelector((state) => state.menu);

    const Icon = item?.icon!;
    const itemIcon = item?.icon ? (
        <Icon stroke={1.5} size="20px" />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selectedItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                height: selectedItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    let itemTarget: LinkTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps: {
        component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
        href?: string;
        target?: LinkTarget;
    } = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url!} target={itemTarget} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id: string) => {
        if (level == 1) {
            dispatch(activeParentItem([id]));
            dispatch(activeItem([""]));
        } else {
            dispatch(activeItem([id]));
        }
        matchesSM && dispatch(openDrawer(false));
    };
    const hasSelect = (level == 1 ? (selectedParentItem?.findIndex((id) => id === item.id)) : (selectedItem?.findIndex((id) => id === item.id)));
    // active menu item on page load
    useEffect(() => {
        const currentIndex:boolean = document.location.pathname.includes(item?.url ?? "");
        if (level === 1) {
            if (currentIndex === true) {
                dispatch(activeParentItem([item.id!]));
            }
        } else {
            if (currentIndex === true) {
                dispatch(activeItem([item.id!]));
            }
        }

        // eslint-disable-next-line
    }, [pathname]);

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                borderRadius: `${borderRadius}px`,
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 1 : 1.25,
                pl: `${level * 24}px`,
                pointerEvents: `${item?.url == 'none' ? 'none' : 'auto'}`
            }}
            selected={hasSelect > -1}
            onClick={() => itemHandler(item.id!)}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>
                {item.id == 'avt' ? (
                    <Avatar
                    src={user && user.avatar ? user.avatar : ''}
                    sx={{
                        ...theme.typography.mediumAvatar,
                        marginRight: '1rem !important',
                        cursor: 'pointer',
                    }}
                    color="inherit"
                />
                ):
                (
                    <>
                    {itemIcon}
                    </>
                )}
                {/* {itemIcon} */}
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant={selectedItem?.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} color="inherit">
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

export default NavItem;
