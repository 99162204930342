import { lazy } from "react";

// project imports
import MinimalLayout from "layout/MinimalLayout";
import NavMotion from "layout/NavMotion";
import Loadable from "ui-component/Loadable";
import GuestGuard from "utils/route-guard/GuestGuard";
import { ROUTE_NAME } from "configs";

// login routing
const AuthLogin = Loadable(lazy(() => import("views/pages/authentication/authentication3/Login3")));
const AuthRegister = Loadable(lazy(() => import("views/pages/authentication/authentication3/Register3")));
const AuthForgotPassword = Loadable(lazy(() => import("views/pages/authentication/authentication3/ForgotPassword3")));
const CompletedResetPassword = Loadable(lazy(() => import("views/pages/authentication/authentication3/CompletedResetPassword")));
// maintenance routing
const MaintenanceError = Loadable(lazy(() => import("views/pages/maintenance/Error")));
const MaintenanceComingSoon1 = Loadable(lazy(() => import("views/pages/maintenance/ComingSoon/ComingSoon1")));
const MaintenanceComingSoon2 = Loadable(lazy(() => import("views/pages/maintenance/ComingSoon/ComingSoon2")));
const MaintenanceUnderConstruction = Loadable(lazy(() => import("views/pages/maintenance/UnderConstruction")));

// landing & contact-us routing
const PagesLanding = Loadable(lazy(() => import("views/pages/landing")));
const PagesContactUS = Loadable(lazy(() => import("views/pages/contact-us")));
const PagesFaqs = Loadable(lazy(() => import("views/pages/saas-pages/Faqs")));
const PagesPrivacyPolicy = Loadable(lazy(() => import("views/pages/saas-pages/PrivacyPolicy")));
const AuthGoogle = Loadable(lazy(() => import("views/pages/authentication/auth-forms/AuthGoogle")));
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
	path: "/",
	element: (
		<NavMotion>
			<GuestGuard>
				<MinimalLayout />
			</GuestGuard>
		</NavMotion>
	),
	children: [
		{
			path: "/login",
			element: <AuthLogin />
		},
		{
			path: "/register",
			element: <AuthRegister />
		},
		{
			path: "/forgot",
			element: <AuthForgotPassword />
		},
		{
			path: "*",
			element: <MaintenanceError />
		},
		{
			path: "/pages/reset-password-completed",
			element: <CompletedResetPassword />
		},
		{
			path: "/pages/coming-soon1",
			element: <MaintenanceComingSoon1 />
		},
		{
			path: "/pages/coming-soon2",
			element: <MaintenanceComingSoon2 />
		},
		{
			path: "/pages/under-construction",
			element: <MaintenanceUnderConstruction />
		},
		{
			path: "/pages/landing",
			element: <PagesLanding />
		},
		{
			path: "/pages/contact-us",
			element: <PagesContactUS />
		},
		{
			path: "/pages/faqs",
			element: <PagesFaqs />
		},
		{
			path: "/pages/privacy-policy",
			element: <PagesPrivacyPolicy />
		},
		{
			path: "/auth/google/callback",
			element: <AuthGoogle />
		}
	]
};

export default LoginRoutes;
