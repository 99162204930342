// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// types
import { DefaultRootStateProps } from 'types';
import { KanbanColumn, KanbanComment, KanbanItem, KanbanUserStory, KanbanProfile } from 'types/kanban';
import { Chance } from 'chance';
import { add, set, sub } from 'date-fns';
import { END_POINT, NOTIFY_NAME, ROUTE_NAME } from 'configs';

const chance = new Chance();

// ----------------------------------------------------------------------
const initialState: DefaultRootStateProps['kanban'] = {
    error: null,
    columns: [],
    columnsOrder: [],
    comments: [],
    items: [],
    profiles: [],
    selectedItem: false,
    userStory: [],
    userStoryOrder: []
};
// =================
const itemIdsData = {
    item1: `${chance.integer({ min: 1000, max: 9999 })}`,
    item2: `${chance.integer({ min: 1000, max: 9999 })}`,
    item3: `${chance.integer({ min: 1000, max: 9999 })}`,
    item4: `${chance.integer({ min: 1000, max: 9999 })}`,
    item5: `${chance.integer({ min: 1000, max: 9999 })}`,
    item6: `${chance.integer({ min: 1000, max: 9999 })}`,
    item7: `${chance.integer({ min: 1000, max: 9999 })}`,
    item8: `${chance.integer({ min: 1000, max: 9999 })}`,
    item9: `${chance.integer({ min: 1000, max: 9999 })}`,
    item10: `${chance.integer({ min: 1000, max: 9999 })}`
};
const commentIdsData = {
    comment1: 'comment-1',
    comment2: 'comment-2',
    comment3: 'comment-3',
    comment4: 'comment-4',
    comment5: 'comment-5'
};
const profileIdsData = {
    profile1: 'profile-1',
    profile2: 'profile-2',
    profile3: 'profile-3'
};
const itemsData: KanbanItem[] = [
    {
        assign: profileIdsData.profile1,
        attachments: [],
        commentIds: [commentIdsData.comment1],
        description: 'Content of item 1',
        dueDate: sub(new Date(), { days: 12 }),
        id: itemIdsData.item1,
        image: false,
        priority: 'low',
        title: 'Online fees payment & instant announcements'
    },
    {
        assign: profileIdsData.profile2,
        attachments: [],
        commentIds: [commentIdsData.comment2, commentIdsData.comment5],
        description: 'Content of item 2',
        dueDate: sub(new Date(), { days: 18 }),
        id: itemIdsData.item2,
        image: false,
        priority: 'high',
        title: 'Creation and Maintenance of Inventory Objects'
    },
    {
        assign: profileIdsData.profile3,
        attachments: [],
        description: 'Content of item 3',
        dueDate: sub(new Date(), { days: 8 }),
        id: itemIdsData.item3,
        image: false,
        priority: 'low',
        title: 'Update React & TypeScript version'
    },
    {
        assign: profileIdsData.profile2,
        attachments: [],
        commentIds: [commentIdsData.comment4],
        description: 'Content of item 4',
        dueDate: sub(new Date(), { days: 6 }),
        id: itemIdsData.item4,
        image: 'profile-back-2.png',
        priority: 'low',
        title: 'Set allowing rules for trusted applications.'
    },
    {
        assign: profileIdsData.profile2,
        attachments: [],
        commentIds: [commentIdsData.comment1, commentIdsData.comment2, commentIdsData.comment5],
        description: 'Content of item 5',
        dueDate: sub(new Date(), { days: 9 }),
        id: itemIdsData.item5,
        image: false,
        priority: 'medium',
        title: 'Managing Applications Launch Control'
    },
    {
        assign: profileIdsData.profile3,
        attachments: [],
        commentIds: [commentIdsData.comment3, commentIdsData.comment4],
        description: 'Content of item 6',
        dueDate: set(new Date(), { hours: 10, minutes: 30 }),
        id: itemIdsData.item6,
        image: false,
        priority: 'medium',
        title: 'Run codemods'
    },
    {
        assign: profileIdsData.profile1,
        attachments: [],
        description: 'Content of item 7',
        dueDate: add(new Date(), { days: 5 }),
        id: itemIdsData.item7,
        image: false,
        priority: 'low',
        title: 'Purchase Requisitions, Adjustments, and Transfers.'
    },
    {
        assign: profileIdsData.profile1,
        attachments: [],
        description: 'Content of item 8',
        dueDate: add(new Date(), { days: 17 }),
        id: itemIdsData.item8,
        image: false,
        priority: 'low',
        title: 'Attendance checking & homework details'
    },
    {
        assign: profileIdsData.profile3,
        attachments: [],
        commentIds: [commentIdsData.comment3],
        description: 'Content of item 9',
        dueDate: add(new Date(), { days: 8 }),
        id: itemIdsData.item9,
        image: false,
        priority: 'high',
        title: 'Admission, Staff & Schedule management'
    },
    {
        assign: profileIdsData.profile2,
        attachments: [],
        commentIds: [commentIdsData.comment5],
        description: 'Content of item 10',
        dueDate: add(new Date(), { days: 12 }),
        id: itemIdsData.item10,
        image: false,
        priority: 'low',
        title: 'Handling breaking changes'
    }
];
const columnIdsData = {
    column1: 'column-1',
    column2: 'column-2',
    column3: 'column-3',
    column4: 'column-4',
    column5: 'column-5',
    column6: 'column-6',
    column7: 'column-7',
    column8: 'column-8',
    column9: 'column-9',
    column10: 'column-10',
    column11: 'column-11',
};
const columnsData: KanbanColumn[] = [
    {
        id: columnIdsData.column1,
        title: 'Step 1: Genarate Lead',
        itemIds: [itemIdsData.item1, itemIdsData.item8, itemIdsData.item9]
    },
    {
        id: columnIdsData.column2,
        title: 'Step 2: Qualify Lead',
        itemIds: [itemIdsData.item3, itemIdsData.item4, itemIdsData.item5]
    },
    {
        id: columnIdsData.column3,
        title: 'Step 3: Pre-approach',
        itemIds: [itemIdsData.item2]
    },
    {
        id: columnIdsData.column4,
        title: 'Step 4: Make Approach',
        itemIds: [itemIdsData.item6, itemIdsData.item7, itemIdsData.item10]
    },
    {
        id: columnIdsData.column5,
        title: 'Step 5: Intitial Meeting',
        itemIds: [itemIdsData.item6, itemIdsData.item7, itemIdsData.item10]
    },
    {
        id: columnIdsData.column6,
        title: 'Step 6: Closing Meeting',
        itemIds: [itemIdsData.item6, itemIdsData.item7, itemIdsData.item10]
    },
    {
        id: columnIdsData.column7,
        title: 'Step 7: Closing Deal',
        itemIds: [itemIdsData.item6, itemIdsData.item7, itemIdsData.item10]
    },
    {
        id: columnIdsData.column8,
        title: 'Step 8: Service Contract',
        itemIds: [itemIdsData.item6, itemIdsData.item7, itemIdsData.item10]
    },
    {
        id: columnIdsData.column9,
        title: 'Step 9: Ask Referral',
        itemIds: [itemIdsData.item6, itemIdsData.item7, itemIdsData.item10]
    },
    {
        id: columnIdsData.column10,
        title: 'Done',
        itemIds: [itemIdsData.item6, itemIdsData.item7, itemIdsData.item10]
    },
    {
        id: columnIdsData.column11,
        title: 'Rejected',
        itemIds: [itemIdsData.item6, itemIdsData.item7, itemIdsData.item10]
    }
];
const columnsOrderData: string[] = [
        columnIdsData.column1,
        columnIdsData.column2,
        columnIdsData.column3,
        columnIdsData.column4,
        columnIdsData.column5,
        columnIdsData.column6,
        columnIdsData.column7,
        columnIdsData.column8,
        columnIdsData.column9,
        columnIdsData.column10,
        columnIdsData.column11,
];
const commentsData: KanbanComment[] = [
    {
        id: commentIdsData.comment1,
        comment: 'Comment 1',
        profileId: profileIdsData.profile1
    },
    {
        id: commentIdsData.comment2,
        comment: 'Comment 2',
        profileId: profileIdsData.profile2
    },
    {
        id: commentIdsData.comment3,
        comment: 'Comment 3',
        profileId: profileIdsData.profile3
    },
    {
        id: commentIdsData.comment4,
        comment: 'Comment 4',
        profileId: profileIdsData.profile2
    },
    {
        id: commentIdsData.comment5,
        comment: 'Comment 5',
        profileId: profileIdsData.profile3
    }
];
const profilesData: KanbanProfile[] = [
    {
        id: profileIdsData.profile1,
        avatar: 'avatar-3.png',
        name: 'Barney Thea',
        time: '2 min ago'
    },
    {
        id: profileIdsData.profile2,
        avatar: 'avatar-1.png',
        name: 'Maddison Wilber',
        time: '1 day ago'
    },
    {
        id: profileIdsData.profile3,
        avatar: 'avatar-2.png',
        name: 'John Doe',
        time: 'now'
    }
];
const userStoryIdsData = {
    userStory1: `${chance.integer({ min: 1000, max: 9999 })}`,
    userStory2: `${chance.integer({ min: 1000, max: 9999 })}`,
    userStory3: `${chance.integer({ min: 1000, max: 9999 })}`,
    userStory4: `${chance.integer({ min: 1000, max: 9999 })}`
};
const userStoryOrderData: string[] = [
    userStoryIdsData.userStory1,
    userStoryIdsData.userStory2,
    userStoryIdsData.userStory3,
    userStoryIdsData.userStory4
];
const userStoryData: KanbanUserStory[] = [
    {
        acceptance: '',
        assign: profileIdsData.profile2,
        columnId: columnIdsData.column4,
        commentIds: [commentIdsData.comment5],
        description: chance.sentence(),
        dueDate: add(new Date(), { days: 12 }),
        id: userStoryIdsData.userStory1,
        priority: 'low',
        title: 'School Management Backend',
        itemIds: [itemIdsData.item1, itemIdsData.item8, itemIdsData.item9]
    },
    {
        acceptance: chance.sentence(),
        assign: profileIdsData.profile3,
        columnId: columnIdsData.column1,
        commentIds: [commentIdsData.comment3],
        description: chance.sentence(),
        dueDate: add(new Date(), { days: 8 }),
        id: userStoryIdsData.userStory2,
        priority: 'high',
        title: 'Inventory Implementation & Design',
        itemIds: [itemIdsData.item2, itemIdsData.item7]
    },
    {
        acceptance: chance.sentence({ words: 10 }),
        assign: profileIdsData.profile3,
        columnId: columnIdsData.column4,
        commentIds: [commentIdsData.comment3, commentIdsData.comment4],
        description: chance.sentence(),
        dueDate: set(new Date(), { hours: 10, minutes: 30 }),
        id: userStoryIdsData.userStory3,
        priority: 'medium',
        title: 'Theme migration from v4 to v5',
        itemIds: [itemIdsData.item3, itemIdsData.item6, itemIdsData.item10]
    },
    {
        acceptance: chance.sentence({ words: 5 }),
        assign: profileIdsData.profile1,
        columnId: columnIdsData.column3,
        commentIds: [commentIdsData.comment4],
        description: chance.sentence(),
        dueDate: sub(new Date(), { days: 8 }),
        id: userStoryIdsData.userStory4,
        priority: 'low',
        title: 'Lunch Beauty Application',
        itemIds: [itemIdsData.item4, itemIdsData.item5]
    }
];
// =================

const slice = createSlice({
    name: 'kanban',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // ADD COLUMN
        addColumnSuccess(state, action) {
            state.columns = action.payload.columns;
            state.columnsOrder = action.payload.columnsOrder;
        },

        // EDIT COLUMN
        editColumnSuccess(state, action) {
            state.columns = action.payload.columns;
        },

        // UPDATE COLUMN ORDER
        updateColumnOrderSuccess(state, action) {
            state.columnsOrder = action.payload.columnsOrder;
        },

        // DELETE COLUMN
        deleteColumnSuccess(state, action) {
            state.columns = action.payload.columns;
            state.columnsOrder = action.payload.columnsOrder;
        },

        // ADD ITEM
        addItemSuccess(state, action) {
            state.items = action.payload.items;
            state.columns = action.payload.columns;
            state.userStory = action.payload.userStory;
        },

        // EDIT ITEM
        editItemSuccess(state, action) {
            state.items = action.payload.items;
            state.columns = action.payload.columns;
            state.userStory = action.payload.userStory;
        },

        // UPDATE COLUMN ITEM ORDER
        updateColumnItemOrderSuccess(state, action) {
            state.columns = action.payload.columns;
        },

        // SELECT ITEM
        selectItemSuccess(state, action) {
            state.selectedItem = action.payload.selectedItem;
        },

        // ADD ITEM COMMENT
        addItemCommentSuccess(state, action) {
            state.items = action.payload.items;
            state.comments = action.payload.comments;
        },

        // DELETE ITEM
        deleteItemSuccess(state, action) {
            state.items = action.payload.items;
            state.columns = action.payload.columns;
            state.userStory = action.payload.userStory;
        },

        // ADD STORY
        addStorySuccess(state, action) {
            state.userStory = action.payload.userStory;
            state.userStoryOrder = action.payload.userStoryOrder;
        },

        // EDIT STORY
        editStorySuccess(state, action) {
            state.userStory = action.payload.userStory;
        },

        // UPDATE STORY ORDER
        updateStoryOrderSuccess(state, action) {
            state.userStoryOrder = action.payload.userStoryOrder;
        },

        // UPDATE STORY ITEM ORDER
        updateStoryItemOrderSuccess(state, action) {
            state.userStory = action.payload.userStory;
        },

        // ADD STORY COMMENT
        addStoryCommentSuccess(state, action) {
            state.userStory = action.payload.userStory;
            state.comments = action.payload.comments;
        },

        // DELETE STORY
        deleteStorySuccess(state, action) {
            state.userStory = action.payload.userStory;
            state.userStoryOrder = action.payload.userStoryOrder;
        },

        // GET COLUMNS
        getColumnsSuccess(state, action) {
            state.columns = action.payload;
        },

        // GET COLUMNS ORDER
        getColumnsOrderSuccess(state, action) {
            state.columnsOrder = action.payload;
        },

        // GET COMMENTS
        getCommentsSuccess(state, action) {
            state.comments = action.payload;
        },

        // GET PROFILES
        getProfilesSuccess(state, action) {
            state.profiles = action.payload;
        },

        // GET ITEMS
        getItemsSuccess(state, action) {
            state.items = action.payload;
        },

        // GET USER STORY
        getUserStorySuccess(state, action) {
            state.userStory = action.payload;
        },

        // GET USER STORY ORDER
        getUserStoryOrderSuccess(state, action) {
            state.userStoryOrder = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getColumns() {
    return async () => {
        try {
            // const response = await axios.get('/api/kanban/columns');
            // dispatch(slice.actions.getColumnsSuccess(response.data.columns));
            dispatch(slice.actions.getColumnsSuccess(columnsData));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getColumnsOrder() {
    return async () => {
        try {
            // const response = await axios.get('/api/kanban/columns-order');
            // dispatch(slice.actions.getColumnsOrderSuccess(response.data.columnsOrder));
            dispatch(slice.actions.getColumnsOrderSuccess(columnsOrderData));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getComments() {
    return async () => {
        try {
            // const response = await axios.get('/api/kanban/comments');
            // dispatch(slice.actions.getCommentsSuccess(response.data.comments));
            dispatch(slice.actions.getCommentsSuccess(commentsData));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProfiles() {
    return async () => {
        try {
            // const response = await axios.get('/api/kanban/profiles');
            // dispatch(slice.actions.getProfilesSuccess(response.data.profiles));
            dispatch(slice.actions.getProfilesSuccess(profilesData));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getItems() {
    return async () => {
        try {
            // const response = await axios.get('/api/kanban/items');
            // dispatch(slice.actions.getItemsSuccess(response.data.items));
            // const res: any = await axios.get(END_POINT.API_LEAD_DROPDOWNLIST);
            // const { statuss, datas } = res.data;
            // if (statuss) {
            //     // console.log(data.items)
            // }
            dispatch(slice.actions.getItemsSuccess(itemsData));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUserStory() {
    return async () => {
        try {
            // const response = await axios.get('/api/kanban/userstory');
            // dispatch(slice.actions.getUserStorySuccess(response.data.userStory));
            dispatch(slice.actions.getUserStorySuccess(userStoryData));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUserStoryOrder() {
    return async () => {
        try {
            // const response = await axios.get('/api/kanban/userstory-order');
            // dispatch(slice.actions.getUserStoryOrderSuccess(response.data.userStoryOrder));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addColumn(column: KanbanColumn, columns: KanbanColumn[], columnsOrder: string[]) {
    return async () => {
        try {
            // const response = await axios.post('/api/kanban/add-column', { column, columns, columnsOrder });
            // dispatch(slice.actions.addColumnSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editColumn(column: KanbanColumn, columns: KanbanColumn[]) {
    return async () => {
        try {
            // const response = await axios.post('/api/kanban/edit-column', { column, columns });
            // dispatch(slice.actions.editColumnSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateColumnOrder(columnsOrder: string[]) {
    return async () => {
        try {
            // const response = await axios.post('/api/kanban/update-column-order', { columnsOrder });
            // dispatch(slice.actions.updateColumnOrderSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteColumn(columnId: string, columnsOrder: string[], columns: KanbanColumn[]) {
    return async () => {
        try {
            // const response = await axios.post('/api/kanban/delete-column', { columnId, columnsOrder, columns });
            // dispatch(slice.actions.deleteColumnSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addItem(
    columnId: string,
    columns: KanbanColumn[],
    item: KanbanItem,
    items: KanbanItem[],
    storyId: string,
    userStory: KanbanUserStory[]
) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/add-item', { columnId, columns, item, items, storyId, userStory });
            dispatch(slice.actions.addItemSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editItem(
    columnId: string,
    columns: KanbanColumn[],
    item: KanbanItem,
    items: KanbanItem[],
    storyId: string,
    userStory: KanbanUserStory[]
) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/edit-item', { items, item, userStory, storyId, columns, columnId });
            dispatch(slice.actions.editItemSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateColumnItemOrder(columns: KanbanColumn[]) {
    return async () => {
        try {
            // const response = await axios.post('/api/kanban/update-item-order', { columns });
            // dispatch(slice.actions.updateColumnItemOrderSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function selectItem(selectedItem: string | false) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/select-item', { selectedItem });
            dispatch(slice.actions.selectItemSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addItemComment(itemId: string | false, comment: KanbanComment, items: KanbanItem[], comments: KanbanComment[]) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/add-item-comment', { items, itemId, comment, comments });
            dispatch(slice.actions.addItemCommentSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteItem(itemId: string | false, items: KanbanItem[], columns: KanbanColumn[], userStory: KanbanUserStory[]) {
    return async () => {
        try {
            // const response = await axios.post('/api/kanban/delete-item', { columns, itemId, userStory, items });
            // dispatch(slice.actions.deleteItemSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addStory(story: any, userStory: KanbanUserStory[], userStoryOrder: string[]) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/add-story', { userStory, story, userStoryOrder });
            dispatch(slice.actions.addStorySuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editStory(story: KanbanUserStory, userStory: KanbanUserStory[]) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/edit-story', { userStory, story });
            dispatch(slice.actions.editStorySuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateStoryOrder(userStoryOrder: string[]) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/update-story-order', { userStoryOrder });
            dispatch(slice.actions.updateStoryOrderSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateStoryItemOrder(userStory: KanbanUserStory[]) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/update-storyitem-order', { userStory });
            dispatch(slice.actions.updateStoryItemOrderSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addStoryComment(storyId: string, comment: KanbanComment, comments: KanbanComment[], userStory: KanbanUserStory[]) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/add-story-comment', { userStory, storyId, comment, comments });
            dispatch(slice.actions.addStoryCommentSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteStory(storyId: string, userStory: KanbanUserStory[], userStoryOrder: string[]) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/delete-story', { userStory, storyId, userStoryOrder });
            dispatch(slice.actions.deleteStorySuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
